import {Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';

@Component({
  selector: 'code-validator',
  templateUrl: './code-validator.component.html',
  styleUrls: ['./code-validator.component.scss'],
})
export class CodeValidatorComponent implements OnInit {

  public sixDigit:number[];
  @ViewChildren('validationCodeBox', { read: ElementRef }) inputs: QueryList<ElementRef>;
  @ViewChild('outOfFocus',{static: false}) outFocus:ElementRef;

  @Output() codeReady: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    this.sixDigit = new Array(6);
  }


  moveFocus(index: number, event: any) {

    try {
      this.sixDigit[index - 1] = event.target.value[0];
    } catch (e) {
      console.error(e);
    }

    if (event.target.value.length > 0 && index < this.sixDigit.length ) {
      console.log(`event.target.value is `,event.target.value);

      console.log(`moveFocus:six digit array is`,this.sixDigit);
      const nextInput = this.inputs.toArray()[index].nativeElement;
      //console.log(`set focus to`,nextInput);
      nextInput.setFocus();
    } else if(event.target.value.length > 0 && index == this.sixDigit.length) {

      //check the validity of
      console.log(`check if the 6 digit code is valid or not`);
      this.outFocus.nativeElement.focus();
      console.log(`focus set on`);
      console.log(this.outFocus.nativeElement);

      let validationcode = '';
      console.log(`six digit array is`,this.sixDigit);
      this.sixDigit.map(value => {
        validationcode+=value;
      });
      console.log(`validationcode  is ${validationcode}`);

      //emit
      this.codeReady.emit(validationcode);

    }

  }

  handleBackspace(index: number, event: KeyboardEvent) {
    console.log(event);
    if (event.key === 'Backspace') {
      event.preventDefault(); // Prevent default backspace behavior

      // Clear current input

      const currentInput = this.inputs.toArray()[index - 1].nativeElement;
      currentInput.value = '';
      this.sixDigit[index-1] = undefined;



      // Move focus to previous input if it exists
      if (index > 1) {
        console.log(this.sixDigit);

        const currentInput = this.inputs.toArray()[index - 1].nativeElement;
        const currentValue = currentInput.value; // Get the actual value from the DOM element


        //const currentValue = this.sixDigit[index - 1];
        //const currentInput = this.inputs.toArray()[index - 1].nativeElement;
        console.log(`current value is ${currentValue}`);
        if( currentValue === undefined || currentValue == "" ){
            const previousInput = this.inputs.toArray()[index - 2].nativeElement;
            console.log(`setting focus to`);
            console.log(previousInput);
            previousInput.setFocus();
            this.sixDigit[index - 2] = undefined;
            previousInput.value = "";
        }

      }
    }
  }

  handlePaste(event: ClipboardEvent) {
    // Previeni il comportamento di default del paste
    event.preventDefault();

    // Prendi il testo incollato
    const pastedText = event.clipboardData?.getData('text') || '';

    // Filtra solo i numeri
    const numbers = pastedText.replace(/[^0-9]/g, '');

    // Prendi solo i primi 6 caratteri se ce ne sono di più
    const validNumbers = numbers.slice(0, 6);

    // Popola l'array sixDigit
    for (let i = 0; i < 6; i++) {
      this.sixDigit[i] = validNumbers[i] ? parseInt(validNumbers[i]) : undefined;
    }

    // Se abbiamo 6 cifre, emetti il codice
    if (validNumbers.length === 6) {
      this.codeReady.emit(validNumbers);
      // Sposta il focus sull'elemento outOfFocus
      this.outFocus.nativeElement.focus();
    } else {
      // Altrimenti, sposta il focus sul prossimo input vuoto
      const nextEmptyIndex = this.sixDigit.findIndex(digit => digit === undefined);
      if (nextEmptyIndex !== -1 && nextEmptyIndex < 6) {
        this.inputs.toArray()[nextEmptyIndex].nativeElement.setFocus();
      }
    }
  }

}
