import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, Router, ActivatedRoute, Params, RouterEvent,NavigationEnd,RouteConfigLoadStart } from '@angular/router';
import { Subscription } from 'rxjs';
import {HttpCancelService} from './services/http-cancel.service';
import {AppComponent} from './app.component';

const routes: Routes = [
 // { path: '', redirectTo: 'login', pathMatch: 'full' },
  //{ path: '', component:AppComponent},

  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: 'mcu-select', loadChildren: () => import('./pages/mcu-select/mcu-select.module').then(m => m.McuSelectPageModule) },

  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule) },
  { path: 'macro-zone', loadChildren: () => import('./pages/macro-zone/macro-zone.module').then(m => m.MacroZonePageModule) },
  { path: 'main-menu', loadChildren: () => import('./pages/main-menu/main-menu.module').then(m => m.MainMenuPageModule) },
  { path: 'history', loadChildren: () => import('./pages/history/history.module').then(m => m.HistoryPageModule) },
  { path: 'graph', loadChildren: () => import('./pages/graph/graph.module').then(m => m.GraphPageModule) },
  { path: 'new-template', loadChildren: () => import('./pages/history/settings/new-template/new-template.module').then(m => m.NewTemplatePageModule) },
  { path: 'templates', loadChildren: () => import('./pages/history/settings/templates/templates.module').then(m => m.TemplatesPageModule) },
  { path: 'macro-zone-setpoints/:id', loadChildren: () => import('./pages/macro-zone-setpoints/macro-zone-setpoints.module').then(m => m.MacroZoneSetpointsPageModule) },
  { path: 'zone', loadChildren: () => import('./pages/zone/zone.module').then(m => m.ZonePageModule) },
  { path: 'schedule', loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.SchedulePageModule) },
  { path: 'settings-on-off', loadChildren: () => import('./pages/settings-on-off/settings-on-off.module').then(m => m.SettingsOnOffPageModule) },
  { path: 'macro-zones', loadChildren: () => import('./pages/macro-zones/macro-zones.module').then(m => m.MacroZonesPageModule) },
  { path: 'settings-menu', loadChildren: () => import('./pages/settings-menu/settings-menu.module').then(m => m.SettingsMenuPageModule) },
  { path: 'system-menu', loadChildren: () => import('./pages/system-menu/system-menu.module').then(m => m.SystemMenuPageModule) },
  { path: 'season', loadChildren: () => import('./pages/season/season.module').then(m => m.SeasonPageModule) },
  { path: 'schedules', loadChildren: () => import('./pages/schedules/schedules.module').then(m => m.SchedulesPageModule) },
  { path: 'timers', loadChildren: () => import('./pages/timers/timers.module').then(m => m.TimersPageModule) },
  { path: 'energy-saving', loadChildren: () => import('./pages/energy-saving/energy-saving.module').then(m => m.EnergySavingPageModule) },
  { path: 'backups', loadChildren: () => import('./pages/backups/backups.module').then(m => m.BackupsPageModule) },
  { path: 'config', loadChildren: () => import('./pages/config/config.module').then(m => m.ConfigPageModule) },
  { path: 'settings-about', loadChildren: () => import('./pages/settings-about/settings-about.module').then(m => m.SettingsAboutPageModule) },
  { path: 'settings-general', loadChildren: () => import('./pages/settings-general/settings-general.module').then(m => m.SettingsGeneralPageModule) },
  { path: 'settings-tech', loadChildren: () => import('./pages/settings-tech/settings-tech.module').then(m => m.SettingsTechPageModule) },
  { path: 'settings-network', loadChildren: () => import('./pages/settings-network/settings-network.module').then(m => m.SettingsNetworkPageModule) },
  { path: 'system-hydronic', loadChildren: () => import('./pages/system-hydronic/system-hydronic.module').then(m => m.SystemHydronicPageModule) },
  { path: 'system-Tank', loadChildren: () => import('./pages/system-tank/system-tank.module').then(m => m.SystemTankPageModule) },
  { path: 'system-ARS', loadChildren: () => import('./pages/system-ars/system-ars.module').then(m => m.SystemArsPageModule) },
  { path: 'system-FNC', loadChildren: () => import('./pages/system-fnc/system-fnc.module').then(m => m.SystemFncPageModule) },
  { path: 'system-Zone', loadChildren: () => import('./pages/system-zone/system-zone.module').then(m => m.SystemZonePageModule) },
  { path: 'system-DHW', loadChildren: () => import('./pages/system-dhw/system-dhw.module').then(m => m.SystemDhwPageModule) },
  { path: 'system-enr', loadChildren: () => import('./pages/system-enr/system-enr.module').then(m => m.SystemEnrPageModule) },
  { path: 'phoenix-setup', loadChildren: () => import('./pages/phoenix-setup/phoenix-setup.module').then(m => m.PhoenixSetupPageModule) },
  { path: 'macro-zones-reorder', loadChildren: () => import('./pages/macro-zones-reorder/macro-zones-reorder.module').then(m => m.MacroZonesReorderPageModule) },
  { path: 'zones-reorder', loadChildren: () => import('./pages/zones-reorder/zones-reorder.module').then(m => m.ZonesReorderPageModule) },
  { path: 'menu-mcu-select', loadChildren: () => import('./pages/menu-mcu-select/menu-mcu-select.module').then(m => m.MenuMcuSelectPageModule) },
  { path: 'day', loadChildren: () => import('./pages/day/day.module').then(m => m.DayPageModule) },
  { path: 'config-item', loadChildren: () => import('./pages/config-item/config-item.module').then(m => m.ConfigItemPageModule) },
  { path: 'config-table', loadChildren: () => import('./pages/config-table/config-table.module').then(m => m.ConfigTablePageModule) },
  { path: 'forgot-password', loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule) },
  { path: 'email-alerts', loadChildren: () => import('./pages/email-alerts/email-alerts.module').then(m => m.EmailAlertsPageModule) },
  { path: 'ema-details', loadChildren: () => import('./pages/ema-details/ema-details.module').then(m => m.EmaDetailsPageModule) },
  { path: 'discovery', loadChildren: () => import('./pages/discovery/discovery.module').then(m => m.DiscoveryPageModule) },
  { path: 'default-templates', loadChildren: () => import('./pages/history/settings/default-templates/default-templates.module').then(m => m.DefaultTemplatesPageModule) },
  { path: 'calibration-start', loadChildren: () => import('./pages/hidew-calibration/calibration-start/calibration-start.module').then(m=>m.CalibrationStartPageModule) },
  { path: 'calibration-phase-one', loadChildren: () => import('./pages/hidew-calibration/calibration-phase-one/calibration-phase-one.module').then(m=>m.CalibrationPhaseOnePageModule) },
  { path: 'calibration-phase-two', loadChildren: () => import('./pages/hidew-calibration/calibration-phase-two/calibration-phase-two.module').then(m=>m.CalibrationPhaseTwoPageModule) },
  { path: 'calibration-phase-three', loadChildren: () => import('./pages/hidew-calibration/calibration-phase-three/calibration-phase-three.module').then(m=>m.CalibrationPhaseThreePageModule )},
  { path: 'phoenix-setup', loadChildren: './pages/phoenix-setup/phoenix-setup.module#PhoenixSetupPageModule' },
  { path: 'system-logics', loadChildren: () => import('./pages/system-logics/system-logics.module').then(m => m.SystemLogicsPageModule) },
  { path: 'help-menu', loadChildren: () => import('./pages/help-menu/help-menu.module').then(m => m.HelpMenuPageModule) },
  { path: 'census/master-user', loadChildren: () => import('./pages/census/master-user/master-user.module').then(m =>m.MasterUserPageModule) },
  { path: 'census/master-user-query', loadChildren: () => import('./pages/census/master-user-query/master-user-query.module').then(m => m.MasterUserQueryPageModule) },
  { path: 'census/master-user-validation', loadChildren: () => import('./pages/census/master-user-validation/master-user-validation.module').then(m => m.MasterUserValidationPageModule) },
  { path: 'census/master-user-validation-valid-account', loadChildren: () => import('./pages/census/master-user-validation-valid-account/master-user-validation-valid-account.module').then(m => m.MasterUserValidationValidAccountPageModule) },
  { path: 'census/master-user-mail-address-format', loadChildren: () => import('./pages/census/master-user-mail-address-format/master-user-mail-address-format.module').then(m => m.MasterUserMailAddressFormatPageModule) },
  { path: 'census/master-user-mail-address-format-update-success', loadChildren: () => import('./pages/census/master-user-mail-address-format-update-success/master-user-mail-address-format-update-success.module').then(m => m.MasterUserMailAddressFormatUpdateSuccessPageModule) },
  { path: 'census/master-user-personal-data-review',
    loadChildren: () => import('./pages/census/master-personal-data-review/master-personal-data-review.module').then(m =>m.MasterPersonalDataReviewPageModule),
    data: { masterUser: true }
  },
  { path: 'census/personal-data-review',
    loadChildren: () => import('./pages/census/master-personal-data-review/master-personal-data-review.module').then(m =>m.MasterPersonalDataReviewPageModule),
    data: { masterUser: false }
  },

  { path: 'census/user-not-designated-master', loadChildren: () => import('./pages/census/user-not-designated-master/user-not-designated-master.module').then(m => m.UserNotDesignatedMasterPageModule) },
  { path: 'census/residence-service-census', loadChildren: () => import('./pages/census/residence-service-census/residence-service-census.module').then(m => m.ResidenceServiceCensusPageModule) },
  { path: 'census/residence-service-category', loadChildren: () => import('./pages/census/residence-service-category/residence-service-category.module').then(m => m.ResidenceServiceCategoryPageModule) },
  { path: 'census/residence-service-mail-address-collection', loadChildren: () => import('./pages/census/residence-service-mail-address-collection/residence-service-mail-address-collection.module').then(m => m.ResidenceServiceMailAddressCollectionPageModule) },
  { path: 'census/residence-service-completion', loadChildren: () => import('./pages/census/residence-service-completion/residence-service-completion.module').then(m => m.ResidenceServiceCompletionPageModule) },
  { path: 'census/master-user-completion', loadChildren: () => import('./pages/census/residence-service-completion/residence-service-completion.module').then(m => m.ResidenceServiceCompletionPageModule) },
  { path: 'census/master-user-confirmation', loadChildren: () => import('./pages/census/master-user-confirmation/master-user-confirmation.module').then(m => m.MasterUserConfirmationPageModule) },
  { path: 'census/master-user-authenticator', loadChildren: () => import('./pages/census/master-user-authenticator/master-user-authenticator.module').then(m => m.MasterUserAuthenticatorPageModule),
    data: { masterUser: true }
  },
  { path: 'census/residence-service-authenticator', loadChildren: () => import('./pages/census/master-user-authenticator/master-user-authenticator.module').then(m => m.MasterUserAuthenticatorPageModule),
    data: {masterUser: false}
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

private sub:Subscription;

constructor( private router:Router,private  route:ActivatedRoute, private httpCancelService:HttpCancelService) {
  	   this.sub=this.router.events.subscribe(event =>{
	         // console.log(event);
	          if(event instanceof RouteConfigLoadStart){

                 // console.warn("ROUTING EVENT!");
                 this.httpCancelService.cancelPendingRequests();

			}// end of Nav Event
		});



  }


}
