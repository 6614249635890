import {Component, HostListener, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {HttpStatus, NetworkWay} from '../../models/http-status';
import {Observable, Subscription} from 'rxjs';
import {SupportService} from '../../services/support.service';
import {ActionSheetController, AlertController, ModalController, Platform, ToastController} from '@ionic/angular';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';

import {Config, DUAL_MODE, SchedulationType, Season} from '../../models/config';
import {AppState} from '../../services/app-state.service';
import {IdleConfig} from '../../actions/config.actions';
import {AbstractConfigPage} from '../abstract-config-page';

import {HttpClient} from '@angular/common/http';

import {ShowPowerOff} from '../../components/tech/tech.component';
import {ChangeTableValues} from '../../actions/tables.actions';
import {ARS, ATU, AUTO_MODE_STYLE, ENR, FNC, FWA, GRP, HYS, MCU, MCZ, MXV, RMS, SCH, Table, TMP, TMR, TNK, ZON} from '../../models/tables';
import {BitArray} from '../../commons/bitarray';
import {FlagsGroup, FlagsHRV, FlagsINT, FlagsNTD, FlagsTH} from '../../commons/flags';
import * as _ from 'lodash';
import {Day, NO_TEMPERATURE} from '../../pages/schedule/commons';
import {ActivatedRoute, NavigationEnd, NavigationExtras, Router} from '@angular/router';
import {Constants} from "../../commons/const";
import {OverrideSetpointComponent} from "./override-setpoint/override-setpoint.component";
import {ARS_Extended, RMS_Extended} from "../../models/tables-extended";

export enum NTD { OFF = 0, OSP = 1, SCH = 2, ON = 3, OSP_ES = 4 }

export enum HUM { OFF = 0, OSP = 1, SCH = 2, ON = 3, OSP_ES = 4 }

export enum HRV { OFF = 0, OSP = 1, SCH = 2, ON = 3, OSP_IAQ = 4 }


@Component({
    selector: 'app-zone',
    templateUrl: './zone.page.html',
    styleUrls: ['./zone.page.scss'],
})
export class ZonePage extends AbstractConfigPage implements OnInit {

    TABLES = ['Config', 'Attributes', 'MCU', 'RMS', 'TMR', 'MCZ', 'ARS', 'ZON', 'GRP', 'HYS', 'MXV', 'FNC', 'SCH', 'TMP', 'ATU', 'ENR', 'TNK', 'FWA'];

    httpStatusSub: Subscription;

    private httpStatus: Observable<HttpStatus>;
    private modality: NetworkWay;

    // TABLES_SCH = ['SCH']
    FlagsGroup = FlagsGroup;
    FlagsTH = FlagsTH; // use 'FlagsTH' in the template
    FlagsINT = FlagsINT; // use 'FlagsINT' in the template
    FlagsHRV = FlagsHRV; // use 'FlagsHRV' in the template
    FlagsNTD = FlagsNTD; // use 'FlagsNTD' in the template

    id: number;
    mczId: number;
    state: boolean;
    integrationState: boolean;
    integrationOnValue: number;
    integrationOffValue: number;
    fcState: boolean;
    fcStateARS: boolean; //fc state for ARS
    season: Season; // 0 = heating
    seasonExe: Season; // 0 = heating

    loaded: boolean = false; // data loaded

    rmsFlags: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    MCU: MCU = null;
    RMS: RMS[] = [];
    MCZ: MCZ[] = [];
    ARS: ARS[] = [];
    ZON: ZON[] = [];
    GRP: GRP[] = [];
    SCH: SCH[] = [];
    HYS: HYS[] = [];
    MXV: MXV[] = [];
    FNC: FNC[] = [];
    TMP: TMP[] = [];
    ATU: ATU[] = [];
    TMR: TMR[] = [];
    ENR: ENR[] = [];
    TNK: TNK[] = [];
    FWA: FWA[] = [];

    esState: boolean; // energy saving
    hrvState: boolean; //hrv co2
    executeCallback: boolean = false;

    // Export for template
    Season = Season;
    roomsLength: number = 0;
    // fncState: boolean[];
    fncs: FNC[] = [];
    hoursAM = []
    hoursPM = []

    schLoaded = false;

    currentSch: SCH;
    chartAM;
    chartPM;
    dayNames: string[] = [];
    // export for template
    NO_TEMPERATURE = NO_TEMPERATURE;
    days: Day[];
    currentDayIdx: number = -1;
    todayIdx: number = -1;


    currentDayIdxTMR: number = -1;

    currentDayLabel: string;
    max: number;
    min: number;
    SchedulationType = SchedulationType;
    triggerSCH = false;
    changeSCH = false;
    currentDateRegionFrom;
    currentDateRegionTo;
    currentDateRegionAMPM;
    //chartLoading = false;
    changingSCH = false;
    changingRoom = false;
    changingDay = false;

    intervalDeamon;
    schPreviewOpened = false;
    currentDayOnOffAM = [];
    currentDayOnOffPM = [];
    // schMode = false;

    itLang = false;

    roomTempTemplate: TMP
    ROOM_TEMPERATURE_NAME = "Room Temperature"

    firstSchChange = false;

    fnc: FNC;
    atuList: ATU[] = []; // It could be [atu] or [atu, secondaryAtu] for example -> passed to the tech component
    connectedATU: ATU[];
    connectedTMR: TMR[];

    tmrVisibility: boolean[];
    fwaVisibility: boolean = false;
    tmrState: boolean[];
    schModeTMR: boolean[];
    idxToShow: number;
    thermH;
    thermC;
    integH;
    integC;
    dehum;

    co2Level = 0;
    rmsCo2: string;

    hourDifference;
    dateInterval;

    firstLoad = false
    loading = true

    floorWarmer: FWA;

    // @ViewChild('schedPrev',{static:false}) mySchedPrev:CollapseComponent;


    private tmrSub: Subscription;
    // noRadiant: boolean;

    constructor(private location: Location,
                public router: Router,
                private activatedRoute: ActivatedRoute,
                protected store: Store<AppState>,
                protected platform: Platform,
                protected alertController: AlertController,
                protected actionSheetController: ActionSheetController,
                public translate: TranslateService,
                private http: HttpClient,
                public supportService: SupportService,
                protected modalCtrl: ModalController,
                protected toastController?: ToastController) {

        super(store, platform, alertController, actionSheetController, translate, toastController);

        this.routerSub = this.router.events.subscribe(event => {

            if (event instanceof NavigationEnd) {

                // al momento non uso, non ne ho bisogno

                // console.warn("zone page ROUTING EVENT! ZONE loadTables");
                // this.load(this.TABLES, _.throttle(this.loadCallback.bind(this), 1000, {"leading": true}))
                this.id = Number(this.activatedRoute.snapshot.queryParamMap.get("id") as unknown);
                this.mczId = Number(this.activatedRoute.snapshot.queryParamMap.get('mczId') as unknown) | 0;

                // console.log(`parameters: id => ${this.id} id type ${typeof(this.id)}, mczId => ${this.mczId}`)
            }// end of Nav Event
        });
    }

    ngOnDestroy() {
        this.routerSub?.unsubscribe();
    }

    // public ngOnInit(): void {
    //   this.id = this.navParams.get('id');
    //   this.mczId = this.navParams.get('mczId') | 0;
    //   this.load(this.TABLES, this.loadCallback);
    //   // this.load(this.TABLES_SCH, _.throttle(this.loadCallbackSCH, 10 * 500));
    // }


    ngAfterViewInit() {
        //console.log('zone page ngAfterViewInit')
        //this.id =(this.activatedRoute.snapshot.queryParamMap.get("id") as unknown) as number;

        //this.mczId =(this.activatedRoute.snapshot.queryParamMap.get('mczId') as unknown) as number | 0;


    }


    ionViewWillEnter() {

        this.idxToShow = -1;
        this.store.dispatch(new IdleConfig());
        // this.mcus = (this.config.currentUser.userOf).concat(this.config.currentUser.guestOf);

        this.httpStatus = this.store.select('httpStatus') as Observable<HttpStatus>;
        this.httpStatusSub = this.httpStatus.subscribe(data => {

            //console.log(`home httpStatus sub modality: ${data.modality}`)
            this.modality = data.modality;
        });
    }

    ionViewDidEnter(): void {

        // this.load(this.TABLES, _.throttle(this.loadCallback.bind(this), 1000, {"leading": true}));
        this.load(this.TABLES, this.loadCallback.bind(this));

        this.executeCallback = true;

        /* this.tmrSub= interval(60000).subscribe(()=>{
              this.updateTimerIconState();
          }) */
    }

    ionViewWillLeave() {

        this.tmrSub?.unsubscribe();

        this.executeCallback = false;
        this.changeSCH = false;
        clearInterval(this.intervalDeamon)
    }

    loadCallback = (): void => {

        // console.log(this.executeCallback);
        // console.log('this.allTablesLoaded');
        // console.log(this.allTablesLoaded());

        if (this.executeCallback && this.allTablesLoaded()) {
            //console.log('zone.page executeCallback true && allTablesLoaded true')

            this.forceLoad();
        }
    }

    updateTimerState(event, i) {
        // console.log(`updateTimerState(${event},${i})`);
        if (event != 1)
            this.schModeTMR[i] = false;
    }


    forceLoad(delay?) {

        // console.error('CHIAMATA FORCELOAD');
        // console.log('this.rms vale')
        // console.log(this.rms_extended);
        // console.log('this.RMS vale')
        // console.log(this.RMS);


        if (!this.tmrVisibility || this.tmrVisibility.length == 0) {
            this.tmrVisibility = new Array(2).fill(false);

        }

        if (!this.tmrState || this.tmrState.length == 0) {
            this.tmrState = new Array(2).fill(false);

        }


        this.fncs = [];
        // console.log('FORCE LOAD')
        if (this.changeSCH) return;
        // console.log('forceload')
        this.state = this.rms.PAR_State != 0;

        // Prepare the RMS flags bit array
        let rms: RMS = this.rms;
        if (rms) {
            // this.rmsFlags = new BitArray(BITS_DESCRIPTION.length).fromNumber(rms.RTU_Flags);
            this.rmsFlags = rms.RTU_Flags;
            rms.RTU_RsIR = (rms.RTU_RsIR == true || rms.RTU_RsIR == "true") ? true : false

        }

        this.esState = this.rms.PAR_ES != 0;
        this.hrvState = this.rms.PAR_HrvOn;


        // console.log(this.rms)

        // console.log(this.integrationState)
        this.fcStateARS = this.rms ? this.rms.PAR_FcEnab : false;
        this.fcState = this.rms ? this.rms.PAR_FcEnab : false;
        this.season = this.grp.RTU_SeasonRef;

        if (this.arsGrp) {

            this.seasonExe = this.arsGrp.RTU_SeasonExe;

        } else {

            this.seasonExe = this.grp.RTU_SeasonExe;
        }

        const integBitArray = new BitArray(25).fromNumber(this.rms.PAR_IntegEnab);

        if (this.seasonExe == 0) {  //0 heating

            this.integrationState = integBitArray.get(0);

        } else { //1 cooling

            this.integrationState = integBitArray.get(1);
        }

        let tmrIndecis = [];

        if (rms) {

            if (rms.CFG_IdxTMR1 != -1) {

                tmrIndecis.push(rms.CFG_IdxTMR1)
            }

            if (rms.CFG_IdxTMR1 != -1) {

                tmrIndecis.push(rms.CFG_IdxTMR2)
            }

            this.connectedTMR = _.filter(this.TMR, x => {

                let found = false;

                for (let i of tmrIndecis) {

                    if (i == x.id) {

                        found = true;
                    }
                }
                return found;
            });

            if (this.schModeTMR == undefined || this.schModeTMR == []) {

                this.schModeTMR = new Array(this.connectedTMR.length).fill(false);
            }

            let k = 0;

            for (let tmr of this.connectedTMR) {
                // console.log('current tmr vale')
                // console.log(j);

                if ((tmr as TMR).PAR_Mode == 0) {

                    this.tmrState[k++] = false;
                } else {

                    this.tmrState[k++] = true;
                }
            }
        }

        this.floorWarmer = this.rms_extended.floorWarmer;

        const primaryARS: ARS = this.getPrimaryArs();
        const secondaryARS: ARS = this.getSecondaryArs();

        if (primaryARS || secondaryARS) {

            let diffIntegOn = (rms.PAR_DiffIntegOn != -3276.8) ? rms.PAR_DiffIntegOn : this.attrs['RMS']["PAR_DiffIntegOn"]["Default"]
            let diffIntegOff = (rms.PAR_DiffIntegOff != -3276.8) ? rms.PAR_DiffIntegOff : this.attrs['RMS']["PAR_DiffIntegOff"]["Default"]

            if (this.sch) {

                let scheduleIsOn = this.rms.scheduleIsOn(this.MCU, this.season);

                // If it's dynamic set point (CFG_Type 2 or 3) && it's ON && there is a valid RTU_SetPoint
                let RTU_SetPoint = this.sch.RTU_SetPoint;

                if (this.sch.CFG_Type === SchedulationType.DualVariable) {

                    RTU_SetPoint = this.seasonExe === 0 ? this.sch.RTU_SetPointH : this.sch.RTU_SetPointC;
                }

                if (this.sch.id !== -1 && (this.sch.CFG_Type === SchedulationType.Variable || this.sch.CFG_Type === SchedulationType.DualVariable) && scheduleIsOn && Constants.RTU_SetPointIsValid(this.sch, RTU_SetPoint)) {

                    this.integrationOnValue = this.integrationOffValue = RTU_SetPoint;
                }

                // everything else (empty schedule, off schedule, on/off schedule, RTU_SetPoint -3276.8)
                else {

                    this.integrationOnValue = (this.seasonExe == 0) ? rms.PAR_SetTempH : rms.PAR_SetTempC
                    this.integrationOffValue = (this.seasonExe == 0) ? rms.PAR_SetTempH : rms.PAR_SetTempC
                }

            } else {

                this.integrationOnValue = (this.seasonExe == 0) ? rms.PAR_SetTempH : rms.PAR_SetTempC
                this.integrationOffValue = (this.seasonExe == 0) ? rms.PAR_SetTempH : rms.PAR_SetTempC
            }

            // If it's currently in override mode -> this.integrationOnValue anf this.integrationOffValue will be based on the override
            const dualSetpointMode = this.getDualSetpointModeFromSeasonExe();

            if (this.rms_extended.getSetpointOverrideInfo(null, dualSetpointMode).isOverride) {

                switch (dualSetpointMode) {

                    case Season.Heating:

                        this.integrationOnValue = this.rms_extended.PAR_SpoSetTempH;
                        this.integrationOffValue = this.rms_extended.PAR_SpoSetTempH;
                        break;

                    case Season.Cooling:

                        this.integrationOnValue = this.rms_extended.PAR_SpoSetTempC;
                        this.integrationOffValue = this.rms_extended.PAR_SpoSetTempC;
                        break;

                    // no dual setpoint
                    default:

                        this.integrationOnValue = this.rms_extended.PAR_SpoSetTemp;
                        this.integrationOffValue = this.rms_extended.PAR_SpoSetTemp;
                        break;
                }
            }

            if (this.seasonExe == 0) {

                this.integrationOnValue -= diffIntegOn;
                this.integrationOffValue -= diffIntegOff;

            } else {

                this.integrationOnValue += diffIntegOn;
                this.integrationOffValue += diffIntegOff;
            }

            if (tmrIndecis.length > 0) {

                //determino per ogni tmr se c'è un ovverride attivo, calcolo id arc30 esempio ed eseguo funzione calcolando la percentuale di tempo consumato
                //document.getElementById("arc1").setAttribute("d", this.describeArc(12, 12, 10, 0, 162));
            }
        }

        let primaryATU: ATU = null;
        let secondaryATU: ATU = null;

        if (primaryARS) {

            primaryATU = _.find(this.ATU, atu => atu.CFG_IdxARS == primaryARS.id);
        }

        if (secondaryARS) {

            secondaryATU = _.find(this.ATU, atu => atu.CFG_IdxARS == secondaryARS.id);
        }

        this.atuList = [primaryATU, secondaryATU];

        //console.error('DOPO IF(ARS)')

        if (this.mczId != 0)
            this.roomsLength = this.RMS.filter(zone => zone.CFG_IdxMCZ == this.mczId).length;
        else
            this.roomsLength = this.RMS.length;

        // riga vecchia
        // this.fncs = _.filter(this.FNC, _fnc => { for(let _idxRMS of _fnc.CFG_IdxRMS){ return _idxRMS==(this.id)  }})

        let _foundFcIdx = -1;

        for (let _f of this.FNC) {
            let arrayToFind = [..._f.CFG_IdxRMS];
            let searchIdx = this.id;
            // let _foundIdx=_.findIndex(arrayToFind,"8");


            for (let idx = 0; idx < arrayToFind.length; idx++) {
                if (arrayToFind[idx] == searchIdx) {

                    _foundFcIdx = idx;
                    this.fncs.push(_f);
                } else {
                    // console.warn(`${arrayToFind[idx]} != 8 e tipo ${typeof(arrayToFind[idx])}`);
                }
            }
        }

        // console.warn('i fancoil di questa RMS sono:')
        // console.log(this.fncs);


        //per qualche motivo che non coprendo la _.findIndex o la indexOf non funzionano, ho anche provato a usare this.id come stringa, e anche come intero, ma niente, restituisce sempre -1
        //this.fncs = _.filter(this.FNC, x => _.findIndex(x.CFG_IdxRMS,this.id.toString()) != -1)


        this.fnc = this.fncs[0];
        // for (var f = 0; f < this.fncs.length; f++) {
        // let fnc = this.fnc[f];

        // reset variables
        this.thermH = false;
        this.thermC = false;
        this.dehum = false;
        this.integH = false;
        this.integC = false;

        if (this.fnc && this.fnc.PAR_ModeFC) {

            const modeBitArray = new BitArray(25).fromNumber(this.fnc.PAR_ModeFC);

            this.thermH = modeBitArray.get(0);
            this.thermC = modeBitArray.get(1);

            // Aggiunta electric strip a funzione d'integrazione fancoil
            // this.integH = modeBitArray.get(2) || this.fnc.CFG_CfgDoElectricHeat != 0;
            this.integH = modeBitArray.get(2); // The active "CFG_CfgDoElectricHeat" should be visible somewhere (fan-coils or zones) in the app (and maybe with an on/off PAR too)
            this.integC = modeBitArray.get(3);
            this.dehum = modeBitArray.get(4);

            let diffIntegOn = (rms.PAR_DiffIntegOn != -3276.8) ? rms.PAR_DiffIntegOn : this.attrs['RMS']["PAR_DiffIntegOn"]["Default"];
            let diffIntegOff = (rms.PAR_DiffIntegOff != -3276.8) ? rms.PAR_DiffIntegOff : this.attrs['RMS']["PAR_DiffIntegOff"]["Default"];

            const dualSetpointMode = this.getDualSetpointModeFromSeasonExe();

            // If it's in override mode -> this.integrationOnValue anf this.integrationOffValue will be based on the override
            if (this.rms_extended.getSetpointOverrideInfo(null, dualSetpointMode).isOverride) {

                switch (dualSetpointMode) {

                    case Season.Heating:

                        this.integrationOnValue = this.rms_extended.PAR_SpoSetTempH;
                        this.integrationOffValue = this.rms_extended.PAR_SpoSetTempH;
                        break;

                    case Season.Cooling:

                        this.integrationOnValue = this.rms_extended.PAR_SpoSetTempC;
                        this.integrationOffValue = this.rms_extended.PAR_SpoSetTempC;
                        break;

                    // no dual setpoint
                    default:

                        this.integrationOnValue = this.rms_extended.PAR_SpoSetTemp;
                        this.integrationOffValue = this.rms_extended.PAR_SpoSetTemp;
                        break;
                }

                if (this.seasonExe == 0) {

                    this.integrationOnValue -= diffIntegOn;
                    this.integrationOffValue -= diffIntegOff;
                } else {

                    this.integrationOnValue += diffIntegOn;
                    this.integrationOffValue += diffIntegOff;
                }
            } else if (this.sch) {

                // console.log(this.sch);
                // console.log(this.integrationOnValue);
                // console.log(this.integrationOffValue);

                if (this.sch.CFG_Type !== this.SchedulationType.Variable && this.sch.CFG_Type !== this.SchedulationType.DualVariable) {

                    if (this.integH && this.seasonExe == 0) {

                        this.integrationOnValue = rms.PAR_SetTempH - diffIntegOn
                        this.integrationOffValue = rms.PAR_SetTempH - diffIntegOff
                    }

                    if (this.integC && this.seasonExe == 1) {

                        this.integrationOnValue = rms.PAR_SetTempC + diffIntegOn
                        this.integrationOffValue = rms.PAR_SetTempC + diffIntegOff
                    }

                    // console.log(this.integrationOnValue);
                } else {

                    if (this.sch.id != -1 && (this.sch.CFG_Type === this.SchedulationType.Variable || this.sch.CFG_Type === this.SchedulationType.DualVariable)) {

                        if (this.integH && this.seasonExe == 0) {

                            const RTU_SetPoint = this.sch.CFG_Type === this.SchedulationType.Variable ? this.sch.RTU_SetPoint : this.sch.RTU_SetPointH; // RTU_SetPointH for: SchedulationType.DualVariable

                            this.integrationOnValue = RTU_SetPoint - diffIntegOn;
                            this.integrationOffValue = RTU_SetPoint - diffIntegOff;
                        }

                        if (this.integC && this.seasonExe == 1) {

                            const RTU_SetPoint = this.sch.CFG_Type === this.SchedulationType.Variable ? this.sch.RTU_SetPoint : this.sch.RTU_SetPointC; // RTU_SetPointC for: SchedulationType.DualVariable

                            this.integrationOnValue = RTU_SetPoint + diffIntegOn;
                            this.integrationOffValue = RTU_SetPoint + diffIntegOff;
                        }
                    }
                }
            } else {

                if (this.integH && this.seasonExe == 0) {

                    this.integrationOnValue = rms.PAR_SetTempH - diffIntegOn
                    this.integrationOffValue = rms.PAR_SetTempH - diffIntegOff
                }

                if (this.integC && this.seasonExe == 1) {
                    this.integrationOnValue = rms.PAR_SetTempC + diffIntegOn
                    this.integrationOffValue = rms.PAR_SetTempC + diffIntegOff
                }
            }
        }

        if (!this.firstLoad) {
            /*setTimeout(() => {
                console.log('end loading')
                this.loading = false
            }, 200)*/
            this.loading = false
        }
        this.firstLoad = true


        // if(this.zones.length == 0 && this.fncs.length == 0){
        //   this.noRadiant = true
        //   // console.log('NO RADIANT')
        // }
        // else {
        //     // if(this.season == 0 && this.rms.CFG_Type ==  ){
        //     //
        //     // }
        //     // else{
        //     //
        //     // }
        //   this.noRadiant = false
        //   // console.log('RADIANT')
        // }


        // }

        this.roomTempTemplate = _.find(this.TMP, tmp => tmp.CFG_Name == this.ROOM_TEMPERATURE_NAME)


        this.loaded = true;

    }

    get rms(): RMS {
        // var rms = ;
        // this.isSameSchedule = this.rms.PAR_IdxSCH_C == this.rms.PAR_IdxSCH_H
        return this.RMS[this.id]
    }

    get rms_extended(): RMS_Extended {

        return RMS_Extended.createExtendedRMS(this.RMS, this.RMS[this.id], this.GRP, this.ZON, this.MCZ, this.SCH, this.FNC, this.HYS, this.TMR, this.ARS, this.ATU, this.ENR, this.TNK, this.FWA);
    }

    get zon(): ZON {
        return _.find(this.ZON, x => x.CFG_IdxRMS == this.id);
    }

    get zones(): ZON[] {
        return _.filter(this.ZON, x => x.CFG_IdxRMS == this.id);
    }

    get hys(): HYS[] {
        try {
            if (this.zones[1] && this.zones[0].CFG_IdxHYS != this.zones[1].CFG_IdxHYS)
                return [this.HYS[this.zones[0].CFG_IdxHYS], this.HYS[this.zones[1].CFG_IdxHYS]]
            else
                return [this.HYS[this.zones[0].CFG_IdxHYS]]
        } catch (ex) {
            return null;
        }
    }

    get noRadiant(): boolean {
        return this.supportService.noRadiant(this.rms, this.zones, this.fncs, this.HYS, this.GRP, null);

        // console.log('checknoradiant')
        /* if (this.zones.length == 0 && this.fncs.length == 0) return true

         var limit = this.zones.length


         for (let i = 0; i < this.zones.length; i++) {
             var zon = this.zones[i]
             var hys = this.HYS[zon.CFG_IdxHYS]
             var hysSeason = this.GRP[hys.CFG_IdxGRP].RTU_SeasonExe
             if (hysSeason == 0 && (zon.PAR_Type == 1 || zon.PAR_Type == 3)) return false // HYS e ZON associate
             if (hysSeason == 1 && (zon.PAR_Type == 2 || zon.PAR_Type == 3)) return false // HYS e ZON associate
         }

         for (let i = 0; i < this.fncs.length; i++) {
             let fnc = this.fncs[i]
             let modeBitArray = new BitArray(25).fromNumber(fnc.PAR_ModeFC);
             let hysH = fnc.CFG_IdxHYS_H != -1 ? this.HYS[fnc.CFG_IdxHYS_H] : -1;
             let hysC = fnc.CFG_IdxHYS_C != -1 ? this.HYS[fnc.CFG_IdxHYS_C] : -1;
             let hysSeasonH = hysH != -1 ? this.GRP[((hysH) as HYS).CFG_IdxGRP].RTU_SeasonExe : -1;
             let hysSeasonC = hysC != -1 ? this.GRP[((hysC) as HYS).CFG_IdxGRP].RTU_SeasonExe : -1;

             if (hysSeasonH == 0 && modeBitArray.get(0) || hysSeasonC == 1 && modeBitArray.get(1))
                 return false
         }

         return true */

    }


    noArs() {
        try {
            return this.RMS[this.id].CFG_IdxARS === -1 && this.RMS[this.id].CFG_IdxSecondaryARS === -1;
        } catch (ex) {
            return true;
        }
    }

    getPrimaryArs(): ARS {

        try {
            return this.ARS[this.RMS[this.id].CFG_IdxARS];

        } catch (e) {

            return null;
        }
    }

    getSecondaryArs(): ARS {

        try {

            return this.ARS[this.RMS[this.id].CFG_IdxSecondaryARS];

        } catch (e) {

            return null;
        }
    }

    get grp(): GRP {
        try {
            return this.GRP[this.RMS[this.id].CFG_IdxGRP];
        } catch (ex) {
            return null;
        }
    }


    get arsGrp(): GRP {

        try {

            //becco ars associato a rms
            const rms = this.RMS[this.id];
            const ars = this.ARS[rms.CFG_IdxARS];
            const secondaryArs = this.ARS[rms.CFG_IdxSecondaryARS];

            if (ars) {

                return this.GRP[ars.CFG_IdxGRP];
            }

            if (secondaryArs) {

                return this.GRP[secondaryArs.CFG_IdxGRP];
            }


        } catch (ex) {

            return null;
        }
    }

    get mcz(): MCZ {
        try {
            return this.MCZ[this.RMS[this.id].CFG_IdxMCZ];
        } catch (ex) {
            return null;
        }
    }

    get sch(): SCH {
        try {
            // if(this.season == 0)
            //   console.log(this.RMS[this.id].PAR_IdxSCH_H)
            // else
            //   console.log(this.RMS[this.id].PAR_IdxSCH_C)
            let sch = (this.season == 0) ? this.SCH[this.RMS[this.id].PAR_IdxSCH_H] : this.SCH[this.RMS[this.id].PAR_IdxSCH_C]
            if (!sch) this.schLoaded = false;
            if (!this.currentSch || this.currentSch && this.currentSch.id != sch.id || this.changingRoom || this.changingDay) {

                if (!this.firstSchChange || this.changingRoom) {

                    this.firstSchChange = true;
                }

                this.changingSCH = true;
                this.changingRoom = false;


            }
            this.currentSch = sch;
            return sch;
            //  this.currentSch;
        } catch (ex) {
            return null;
        }
    }


    updateRttCanHum($event, ars: ARS_Extended) {

        ars.RTT_CanHum = $event.detail.checked;
    }

    /**
     * Switch room on/off
     */
    stateUpdated = ($event): void => {
        this.state = $event.detail.checked;
        if (!this.loaded) { // data not yet loaded
            return;
        }
        this.rms.PAR_State = this.state ? 1 : 0;
        this.store.dispatch(new ChangeTableValues('RMS', this.id, {PAR_State: this.rms.PAR_State}));
    }

    /**
     * Switch integration on/off
     */
    integrationUpdated($event): void {
        this.integrationState = $event.detail.checked;
        if (!this.loaded) { // data not yet loaded
            return;
        }
        let integBitArray = new BitArray(25).fromNumber(this.rms.PAR_IntegEnab);
        integBitArray.set(this.seasonExe, this.integrationState)
        this.rms.PAR_IntegEnab = integBitArray.toNumber()
        this.store.dispatch(new ChangeTableValues('RMS', this.rms.id, {PAR_IntegEnab: this.rms.PAR_IntegEnab}));
    }


    /**
     * Switch integration on/off
     */
    fcUpdated($event): void {
        this.fcState = $event.detail.checked;
        if (!this.loaded) { // data not yet loaded
            return;
        }
        this.rms.PAR_FcEnab = this.fcState;
        this.store.dispatch(new ChangeTableValues('RMS', this.rms.id, {PAR_FcEnab: this.rms.PAR_FcEnab}));
    }

    /*
     * Show the menu
     */
    openMenu = ($event): void => {

        if ($event) {
            $event.stopPropagation();
        }

        const options: any[] = [];

        if (this.isAutoModeStyleDualSetpoint()) {

            const heatingIsInOverride = this.rms_extended.getSetpointOverrideInfo(null, DUAL_MODE.heating).isOverride;
            const coolingIsInOverride = this.rms_extended.getSetpointOverrideInfo(null, DUAL_MODE.cooling).isOverride;

            options.push({text: 'OVERRIDE_SETPOINT_HEATING', disabled: this.noRadiant || this.rms_extended.autoModeRmsModeNotPresent(0), handler: () => this.overrideSetpoint(DUAL_MODE.heating, coolingIsInOverride)});
            options.push({text: 'OVERRIDE_SETPOINT_COOLING', disabled: this.noRadiant || this.rms_extended.autoModeRmsModeNotPresent(1), handler: () => this.overrideSetpoint(DUAL_MODE.cooling, heatingIsInOverride)});
        }

        else {

            options.push({text: 'OVERRIDE_SETPOINT', disabled: this.noRadiant, handler: () => this.overrideSetpoint()});
        }

        if (this.config.admin) {

            options.unshift({
                text: 'RENAME_ZONE',
                handler: () => this.rename('RMS', this.id, 'RENAME', 'RENAME_ZONE_TEXT', this.RMS)
            });
        }

        if (this.roomTempTemplate && this.config.admin) {

            options.push({
                text: 'SHOW_TODAY_TEMPS',
                handler: () => this.goGraph(this.rms, this.currentDateTime.dateTimeAsMoment.format('YYYY-MM-DD'))
            });
        }

        this.showMenu(this.RMS[this.id].CFG_Name, options);
    }

    // -----------------------------------------------------------------------------------------------------------------

    // Don't delete (used in show-zone-override.component.html)
    async cancelOverride(dualSetpointMode: DUAL_MODE = null) {

        let PAR_SpoPeriod = 'PAR_SpoPeriod';
        let RTU_SpoRemain = 'PAR_SpoPeriod';

        // It's an override heating of dual setpoint
        if (dualSetpointMode === DUAL_MODE.heating) {

            PAR_SpoPeriod = 'PAR_SpoPeriodH';
            RTU_SpoRemain = 'PAR_SpoPeriodH';
        }

        // It's an override cooling of dual setpoint
        if (dualSetpointMode === DUAL_MODE.cooling) {

            PAR_SpoPeriod = 'PAR_SpoPeriodC';
            RTU_SpoRemain = 'PAR_SpoPeriodC';
        }

        const resetOverride = () => {

            this.store.dispatch(new ChangeTableValues('RMS', this.id, {[PAR_SpoPeriod]: 0, [RTU_SpoRemain]: 0}));
            this.checkAndCleanOverrides(this.mcz, this.RMS, PAR_SpoPeriod, RTU_SpoRemain);

            // In case I have to reset the other override as well (For example: from a 70° / 72° setpoint zone, I put an override of 80° / 82°. If I now cancel the 82° cooling override, I have to cancel the heating override as well, otherwise the cooling setpoint can't go back to 72°)
            if (violation.present) {

                const PAR_SpoPeriod2 = dualSetpointMode === DUAL_MODE.heating ? 'PAR_SpoPeriodC' : 'PAR_SpoPeriodH';
                const RTU_SpoRemain2 = dualSetpointMode === DUAL_MODE.heating ? 'RTU_SpoRemainC' : 'RTU_SpoRemainH';

                this.store.dispatch(new ChangeTableValues('RMS', this.id, {[PAR_SpoPeriod2]: 0, [RTU_SpoRemain2]: 0}));
                this.checkAndCleanOverrides(this.mcz, this.RMS, PAR_SpoPeriod2, RTU_SpoRemain2);
            }
        }

        const SpoSetTemp = dualSetpointMode === DUAL_MODE.heating ? this.rms.PAR_SetTempH : this.rms.PAR_SetTempC;
        const violation = this.getDualSetpointOverrideMinimumDifferenceViolation(null, dualSetpointMode, this.MCU, SpoSetTemp, this.rms_extended, true);
        const message = violation.present ? `${this.translate.instant('RESET_OVERRIDE_MESSAGE')}<br><br><span class="messana-red">${this.translate.instant(violation.text)}</span>`: 'RESET_OVERRIDE_MESSAGE';

        await this.confirmPopup(null, 'RESET_OVERRIDE_TITLE', message, 'YES', resetOverride, null, 'CLOSE');
    }

    // -----------------------------------------------------------------------------------------------------------------

    // If you want to open the "normal" override setpoint, dualSetpointMode is null. Otherwise, pass either DUAL_MODE.heating (0), or DUAL_MODE.cooling (1) to open the specific dual setpoint override
    async overrideSetpoint(dualSetpointMode: DUAL_MODE = null, otherDualSetpointModeIsInOverride = false) { // When "otherDualSetpointModeIsInOverride" is true, I apply more restrictions to the temperature min and max override, based on the currently active override.

        const rms_extended = this.rms_extended;

        const newSchedule = await this.modalCtrl.create({
            component: OverrideSetpointComponent,
            cssClass: 'newScheduleModal SetpointOverrideModal',
            componentProps: {
                MCU: this.MCU,
                table: 'RMS',
                rmsOrMczExtended: rms_extended,
                attrs: this.attrs,
                config: this.config,
                isApp: this.app,
                dualSetpointMode: dualSetpointMode,
                otherDualSetpointModeIsInOverride: otherDualSetpointModeIsInOverride,
                page: this
            }
        });

        await newSchedule.present();
    }

    /*
     * Go to Home Page
     */
    goGraph(item: Table, day: string) {

        let navigationExtras: NavigationExtras = {
            queryParams: {
                template: this.roomTempTemplate,
                item: item,
                day: day
            }
        };
        this.router.navigate(["/graph"], navigationExtras);
    }

    /**
     * Show prev room
     */
    public prev(): void {
        // if (this.changingSCH) return;
        if (this.mczId !== undefined && this.mczId !== null && this.mczId != 0) {
            // Prev room in the macro zone
            let rooms = _.map(_.sortBy(this.RMS.filter(zone => zone.CFG_IdxMCZ == this.mczId), 'CFG_ViewOrder'), 'id') as number[];
            if (rooms.length > 0) {
                this.id = rooms[(rooms.indexOf(this.id) + rooms.length - 1) % rooms.length];
            }
        } else {
            // Prev room
            let rooms = _.map(_.sortBy(this.RMS, 'CFG_ViewOrder'), 'id') as number[];
            if (rooms.length > 0) {
                this.id = rooms[(rooms.indexOf(this.id) + rooms.length - 1) % rooms.length];
            }
        }
        this.changeRoom()
    }

    /**
     * Show next room
     */
    public next(): void {
        let currentId = this.id;

        // if (this.changingSCH) return;
        if (this.mczId !== undefined && this.mczId !== null && this.mczId != 0) {
            // console.log('primo if');
            // Next room in the macro zone
            let rooms = _.map(_.sortBy(this.RMS.filter(zone => zone.CFG_IdxMCZ == this.mczId), 'CFG_ViewOrder'), 'id') as number[];
            // console.log('vettore romms')
            // console.log(rooms);
            if (rooms.length > 0) {
                // console.log(`rooms.indexOf(this.id) ${rooms.indexOf(this.id)} typeof ${typeof(this.id)}`)
                this.id = rooms[(rooms.indexOf(this.id) + 1) % rooms.length];
            }
        } else {
            // console.log('secondo if')
            // Next room in the macro zone
            let rooms = _.map(_.sortBy(this.RMS, 'CFG_ViewOrder'), 'id') as number[];
            // console.log('vettore romms')
            // console.log(rooms);
            if (rooms.length > 0) {
                this.id = rooms[(rooms.indexOf(this.id) + 1) % rooms.length];
            }
        }


        // console.log(`id corrente ${currentId}, prossimo id ${this.id}`)

        this.changeRoom()
    }

    changeRoom() {
        this.location.replaceState(
            this.router.createUrlTree(
                ['/zone'],
                {queryParams: {'id': this.id, 'mczId': this.mczId}}
            ).toString()
        );

        // this.clearPreview()
        // this.schLoaded = false;
        this.state = false;
        this.currentDayIdx = this.todayIdx;
        this.changingRoom = true;
        // this.schMode = false;
        /*  if(this.mySchedPrev!=undefined){
              console.warn('this.mySchedPrev._checked=false;');
              this.mySchedPrev._checked=false;
          } else {
              console.error('mySchedPrev è undefined');
          } */

        this.forceLoad();
    }

    /**
     * Change the RHMode (Dew Point o Humidity)
     */
    changeRhMode = (RhMode: boolean): void => {
        if (!this.loaded) { // data not yet loaded
            return;
        }

        this.MCU.PAR_RhMode = RhMode;
        this.store.dispatch(new ChangeTableValues('MCU', 0, {PAR_RhMode: RhMode}));
    }

    /**
     * Change the RHMode to Dew Point
     */
    changeRhModeDP($event) {
        this.changeRhMode(true);
    }

    /**
     * Change the RHMode to Hum
     */
    changeRhModeHum($event) {
        this.changeRhMode(false);
    }

    /**
     * Switch ES on/off
     */
    esStateUpdated = ($event): void => {
        // console.log('es state update,event vale')
        // console.log($event);
        this.esState = $event.detail.checked;
        if (!this.loaded) { // data not yet loaded
            return;
        }

        this.rms.PAR_ES = this.esState ? 1 : 0;
        this.store.dispatch(new ChangeTableValues('RMS', this.rms.id, {PAR_ES: this.rms.PAR_ES}));
    }

    /**
     * Switch ES on/off
     */
    hrvStateUpdated = ($event): void => {
        this.hrvState = $event.detail.checked;
        if (!this.loaded) { // data not yet loaded
            return;
        }

        this.rms.PAR_HrvOn = this.hrvState;
        this.store.dispatch(new ChangeTableValues('RMS', this.rms.id, {PAR_HrvOn: this.rms.PAR_HrvOn}));
    }

    //------------------------------ FNC ------------------
    /**
     * Switch FNC-i on/off
     */
    fncUpdated = (i): void => {
        if (!this.loaded) { // data not yet loaded
            return;
        }
    }

    /**
     * Write FNC PAR_ON changes
     */
    fncClick(fnc: FNC) {
        // console.log(fnc)
        this.store.dispatch(new ChangeTableValues('FNC', fnc.id, {PAR_On: fnc.PAR_On}));
    }

    changeFNCMode = (fnc: FNC, manual: boolean): void => {
        this.store.dispatch(new ChangeTableValues('FNC', fnc.id, {PAR_Manual: manual}));
    }


    getCurrentTime(systemStatus): String {
        try {
            return systemStatus.uptime.substring(0, systemStatus.uptime.indexOf(" ", 1))
        } catch (ex) {
            return "--:--"
        }
    }

    //questo metodo non lo uso più, lo commento

    /* previewTimeout() {
         setTimeout(() => {
             // console.log('previewTimeout:' + this.schMode)
             if (!this.schMode) {
                 // console.log('trigger close')
                 // clearInterval(this.intervalDeamon)
                 this.clearPreview();
                // this.schMode = false; marx86@gmail.com 5/9/2019
                 // console.log('end previewTimeout:' + this.schMode)
             }
         }, 1000 * 60 * 2)
     } */

    scheduleStyle = (mode: string, ars: ARS): string => {
        if (!ars) return ""
        switch (mode) {
            case 'HRV':
                return (ars.RTU_HrvIR) ? 'thermal-in-range' : 'thermal-disabled'
            case 'FC':
                return (ars.RTU_FcIR) ? 'thermal-in-range' : 'thermal-disabled'
            default:
                return ""
        }
    }

    onChangeModeSchedule(mode: string, ars, i) {
        // switch(mode){
        //     case "HRV":
        //         ars.PAR_HrvMode =  (!this.hrvState[i]) ? 0 : ars.PAR_Mode_HRV
        //         // console.log(ars.PAR_HrvMode)
        //         break;
        //     case "FC":
        //         ars.PAR_FcMode =  (!this.fcState[i]) ? 0 : ars.PAR_Mode_FC
        //         break;
        //     default:
        //         console.log('ERROR update Mode')
        //     }
    }

    showCO2() {
        //Messana Office, AF, Malibú, Irving
        if (!this.MCU.TEC_ShowIAQ) return false
        // if(this.MCU.CFG_Code != "0001" && this.MCU.CFG_Code != "2016-up" && this.MCU.CFG_Code != "2013-107" && this.MCU.CFG_Code != "2017-187") return false
        if (!this.rms) return false;
        if (this.rms.CFG_Type != 9 && this.rms.CFG_Type != 7) return false
        if (this.rms.CFG_Type == 7 && this.rms.RTT_Type != 12) return false

        if (this.rms.RTU_CO2 === undefined || this.rms.RTU_CO2 === -3276.8 || this.rms.RTU_CO2 === -32768) return false
        if (this.rms.RTU_CO2 <= 700) this.co2Level = 0
        else if (this.rms.RTU_CO2 <= 1000) this.co2Level = 1
        else if (this.rms.RTU_CO2 <= 1600) this.co2Level = 2
        else this.co2Level = 3
        // prima c'era High
        this.rmsCo2 = (this.rms.RTU_CO2 > 2100) ? "Bad" : this.rms.RTU_CO2 + " ppm"
        return true
    }

    disableHumSetpiont(ars: ARS) {
        return !(ars.PAR_HumMode == HUM.OSP)
    }

    showHumSetpoint(ars: ARS) { // non in cooling
        return this.MCU.PAR_SpHumZ;
    }

    isIaqAirSetpoint(ars: ARS) {

        return this.MCU.PAR_SpIaqZ && ars.PAR_HrvMode == HRV.OSP_IAQ;
    }

    prevNext(key) {
        // console.log(key)
    }

    @HostListener('document:keypress', ['$event'])
    handleDeleteKeyboardEvent(event: KeyboardEvent) {
        // console.log(event.key)
        if (event.key === 'Delete') {
            // remove something...
        }
    }


    humIconStyle = (): {} => {
        return {
            'text-align': "right",
            'opacity': (this.rms.RTU_Flags[this.FlagsGroup.NTD] == this.FlagsNTD.HUM) ? 1 : 0.3
        }
    }



    intIconStyle = (): {} => {
        return {
            'text-align': 'right',
            'flex': '0.6'
        }
    }

    showPowerOff(showPowerOffIconOnly = false) {

        return new ShowPowerOff(this.MCU.PAR_On, true, showPowerOffIconOnly);
    }

    getIntegrationOnLabel() {

        // If I have a variable temp schedule (type 2) out of schedule (!RTU_On) and there is not an override -> show a text with "PAR_DiffIntegOn"
        if (this.sch && (this.sch.CFG_Type === this.SchedulationType.Variable || this.sch.CFG_Type === this.SchedulationType.DualVariable) && !this.sch.RTU_On && !this.rms_extended.getSetpointOverrideInfo(null, this.getDualSetpointModeFromSeasonExe()).isOverride) {

            const INTEGRATION_ON = this.seasonExe === 0 ? 'ON_BELOW_STATIC_TEXT' : 'ON_ABOVE_STATIC_TEXT';
            return `${this.translate.instant(INTEGRATION_ON)} ${this.rms.PAR_DiffIntegOn}°`;
        }

        // otherwise show "On above" / "On below"
        else {

            const INTEGRATION_ON = this.seasonExe === 0 ? 'ON_BELOW' : 'ON_ABOVE';
            return `${this.translate.instant(INTEGRATION_ON)} ${this.integrationOnValue}°`;
        }
    }

    getIntegrationOffLabel() {

        // If I have a variable temp schedule (type 2) out of schedule (!RTU_On) and there is not an override -> show a text with "PAR_DiffIntegOff"
        if (this.sch && (this.sch.CFG_Type === this.SchedulationType.Variable || this.sch.CFG_Type === this.SchedulationType.DualVariable) && !this.sch.RTU_On && !this.rms_extended.getSetpointOverrideInfo(null, this.getDualSetpointModeFromSeasonExe()).isOverride) {

            const INTEGRATION_OFF = this.seasonExe === 0 ? 'OFF_BELOW_STATIC_TEXT' : 'OFF_ABOVE_STATIC_TEXT';
            return `${this.translate.instant(INTEGRATION_OFF)} ${this.rms.PAR_DiffIntegOff}°`;
        } else {

            return `${this.translate.instant('INTEGRATION_OFF')} ${this.integrationOffValue}°`;
        }
    }

    async goToSystemArs(ars: ARS_Extended) {

        if (this.config.admin) {

            await this.router.navigate(['/system-ARS'], {queryParams: {id: ars.id}, fragment: `conv-int-ars-${ars.id}`});
        }
    }

    selectMax(a: any, b: any) {
        if (a >= b)
            return a;
        else
            return b;
    }

    trackByIndex(index, item) {

        return index; // index or item.id
    }

    trackById(index, item) {

        return item.id; // index or item.id
    }

    isAutoModeStyleDualSetpoint() {
        return this.GRP[this.rms?.CFG_IdxGRP]?.PAR_AutoModeStyle == AUTO_MODE_STYLE.DUAL_SETPOINT && this.GRP[this.rms?.CFG_IdxGRP]?.PAR_Season != 0 && this.GRP[this.rms?.CFG_IdxGRP]?.PAR_Season != 1 && this.GRP[this.rms?.CFG_IdxGRP]?.PAR_Season != -1;
    }

    private getDualSetpointModeFromSeasonExe() {

        return this.isAutoModeStyleDualSetpoint() ? this.seasonExe : null;
    }

    autoDualModeIntegrationReallyPresent() {

        if (this.isAutoModeStyleDualSetpoint()) {

            // I can't do cooling integration if (for example) in my zone there are no cooling sources
            return !this.rms_extended.autoModeRmsModeNotPresent(this.rms_extended.grp.RTU_SeasonExe);
        }

        return true;
    }

    showNtdSetpoint() {

        return this.MCU.PAR_SpDehZ && !this.MCU.TEC_Bioset;
    }

    dehumidificationPresent() {

        let dehumidificationPresent = false;

        for (const ars_extended of this.rms_extended.arsList) {

            if (this.loaded && (ars_extended?.realCanNtd && this.showNtdSetpoint() || this.dehum) && !this.noRadiant) {

                dehumidificationPresent = true;
            }
        }

        return dehumidificationPresent;
    }

    humidificationPresent() {

        let humidificationPresent = false;

        for (const ars_extended of this.rms_extended.arsList) {

            if (this.loaded && ars_extended?.realCanHum && this.showHumSetpoint(ars_extended) && !this.noRadiant) {

                humidificationPresent = true;
            }
        }

        return humidificationPresent;
    }

    dehumidificationCollapseIsNotLast() {

        return this.humidificationPresent() || this.floorWarmer || this.connectedTMR.length > 0 || (!this.noArs() || this.fncs?.length > 0 || this.hys);
    }

    onChangeSchedule(event: any) {

        this.rms_extended.onChangeSchedule(event, this.MCU, this.store, this.translate, this.alertController)
    }

    onAeraulicChange(event: any) {

        if (!this.loaded) {

            return;
        }
        this.rms.PAR_OnAir = event.detail.checked;

        this.store.dispatch(new ChangeTableValues('RMS', this.id, {PAR_OnAir: this.rms.PAR_OnAir}));
    }

    showAeraulicSettings() {

        for (const fnc of this.fncs) {

            // If at least 1 Fancoil has ventilation active -> I show the toggle
            const modeBitArray = new BitArray(8).fromNumber(fnc.PAR_ModeFC);

            if (modeBitArray.get(5)) {

                return true;
            }
        }

        const atuHasAeraulicSettings = (rms: RMS, ars: ARS)=> {

            if (ars?.RTT_CanHrv && this.isIaqAirSetpoint(ars) && (rms.CFG_Type === 9 || rms.RTT_Type === 12)) {

                return true;
            }

            return false;
        }

        // If the primary or secondary ATU has HRV -> I show the toggle
        const primaryArsHasAeraulicSettings = atuHasAeraulicSettings(this.rms_extended, this.rms_extended?.ars);
        const secondaryArsHasAeraulicSettings = atuHasAeraulicSettings(this.rms_extended, this.rms_extended?.secondaryArs);

        if (primaryArsHasAeraulicSettings) {

            return true;
        }

        if (secondaryArsHasAeraulicSettings) {

            return true;
        }
    }
}
