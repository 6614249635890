import {User} from './user';

export enum Debian { Jessie = 8, Buster = 10 }

export enum Operation { Off = 0, On = 1, Follow = 2, Timer = 3 }

export enum Season { Heating = 0, Cooling = 1 }

export enum Humidity { Relative = 0, DewPoint = 1 }

export enum MacroZoneMode { Off = 0, EnergySaving = 1, On = 2 }

export enum ZoneState { Off = 0, On = 1 }

export enum ZoneMode { EnergySaving = 0, Schedulation = 1, AlwaysOn = 2, ScheduleDynamic = 3 }

export enum SchedulationType { OnOff = 0, Season = 1, Variable = 2, DualVariable = 3 }

export enum DUAL_MODE {heating = 0, cooling = 1, heatingAndCooling = 2}

export const minDifferenceHAndCDualVariable = 2;


/**
 * MCU       - browser connected to http://MCU_LOCAL_IP
 *           - no mcu selection
 *           - login URL: (relative) /api/login
 *           - API URL: (relative) /api/
 * Portal    - browser connected to https://support.radiantcooling.com/
 *           - mcu selection
 *           - login URL: (relative) /portal/login
 *           - API URL: (relative) /apiproxy/<MCUID>/
 * App       - app
 *           - mcu selection
 *           - login URL: (absolute) https://support.radiantcooling.com/portal/login
 *           - API URL: (absolute) https://support.radiantcooling.com/apiproxy/<MCUID>
 * IonicTest - browser connected at http://localhost:8001
 *           - mcu selection
 *           - login URL: (absolute) https://support.radiantcooling.com/portal/login
 *           - API URL: (absolute) https://support.radiantcooling.com/apiproxy/<MCUID>
 * Test      - used for tests
 */
export enum EnvironmentType {
    MCU = 0, Portal = 1, App = 2, IonicTest = 3, Test = 4
}

export const DEFAULT_LANGUAGE = 'en';

//export const PORTAL_URL = 'https://backup.radiantcooling.com';
export const PORTAL_URL = 'https://service.radiantcooling.com';

/**
 Bit 0   by parameter.
 Bit 1   by room sensor.
 Bit 2   by supervisor.
 Bit 3   by digital input.
 */
export enum SeasonMode {
    Manual = 0, Parameter = 1, RoomSensor = 2, Supervisor = 4, DigitalInput = 8
}

export enum PlatformMode {App = '[App - Android - iOs]', Browser = '[Browser - Desktop - MobileWeb]'}

/**
 * GUI Settings, stored in the browser/app
 */
export class Config {

    constructor(
        public platform: PlatformMode = null,
        public isAndroid: boolean = false,
        public isIos: boolean = false,
        public baseUrl: string = null,
        public loginUrl: string = null,
        public logoutUrl: string = null,
        public termsUrl: string = null,
        public addFavUrl: string = null,
        public getFavUrl: string = null,
        public removeFavUrl: string = null,
        public portalUrl: string = null,
        public environment: EnvironmentType = null,
        // logged in user
        public currentUser: User = null,
        // current MCU
        public currentMcu: string = null,
        // user is admin of the current MCU
        public admin: boolean = false,
        // tech mode on/off
        public techModeHhdraulicSystems: boolean = true,
        public techModeAirSystems: boolean = true,
        public techModeEnergyResources: boolean = true,
        public techModeDomesticHotWater: boolean = true,
        public techModeAccumulationTanks: boolean = true,
        public techModeFanCoils: boolean = true,
        public techModeAlarmsWarnings: boolean = true,
        public useLocalSystemAtStartup: boolean = false,
        public useBottomTabsNavigation: boolean = true,
        public lastTemplate: string = "0",
        // general settings
        public language: string = DEFAULT_LANGUAGE,
        public humidity: Humidity = Humidity.Relative,
        //public acceptedTerms:boolean=false,

        public loaded: boolean = false, // config loaded
        public loading: boolean = false, // config loading in progress
        public busy: boolean = false,
        public loadExcludedKeys: boolean = false, // load the excluded keys from the backend (config mode)
        public backendCompliantRelease200: boolean = false, // backend compliat to release 2.0.0 wifi -  data connection, networkpage update network
        public zeroConfFromGuest: boolean = false,
        public remoteTimeoutDispatched:boolean = false,
        public attributes = null,
        public appCurrentVersionCached = '') {
    }


    public isRH = (_config) => {
        return _config.humidity == Humidity.Relative;
    }
    public isDP = (_config) => {
        return _config.humidity == Humidity.DewPoint;
    }


    public formatDate = (d: string) => {

        let date_time = d.split(' ');
        let _mdY = date_time[0].split('-');
        let _hms = date_time[1].split(':');
        let year = _mdY[2];
        let month = _mdY[0];
        let day = _mdY[1];
        let hours = _hms[0];
        let minutes = _hms[1];
        let seconds = _hms[2];

        // console.log(`h ${hours} m ${minutes}`)


        return {
            'year': parseInt(year),
            'month': parseInt(month),
            'day': parseInt(day),
            'hours': parseInt(hours),
            'minutes': parseInt(minutes),
            'seconds': parseInt(seconds)
        };
    }

}
