export const TRANSLATIONS = {

    "AAC_DETAIL": "Comfort adattativo",
    //"AAC_DETAIL": "Impostazioni comfort adattativo",
    "ACCESS_MODALITY": "Modalità di accesso",
    "NAVIGATION": "Navigazione",
    "NAVIGATION_DESCRIPTION": "Abilita la navigazione tramite tabs",
    "ACTIVE_KEYS": "Chiavi",
    "ADD_ZONES": "Aggiungi zone",
    "ADDRESS": "Indirizzo",
    "ADVANCED_SETTINGS": "Impostazioni avanzate",
    "ADVANCED_OPERATIONS": "Operazioni avanzate",
    "ADVANCED_CONF_MODE": "Modalità conf. avanzata",
    "SWITCH_LOCAL_SYSTEM_STARTUP": "Connettiti automaticamente al sistema locale all'avvio",
    "ENTER_ADVANCED_CONF_MODE": "Inserire il codice per accedere alla modalità configurazione.",
    "AIR_HUM_RH": "RH ambiente",
    "AIR_HUMIDITY_RH": "Umiditá dell'aria fresca RH",
    "AIR_HUM_DP": "DP ambiente",
    "AIR_HUMIDITY_DP": "Umiditá dell'aria fresca DP",
    "AIR_SYSTEM": "Impianto aria",
    "AIR_UNIT_SCHEDULE": "Schedulazione impianto aria",
    "AIR_UNITS": "Impianto aria",
    "ALARM": "Allarmi",
    "ALARMS": "Allarmi",
    "ALARMS_WARNINGS": "Bottone Allarmi e avvertenze",
    "WARNING": "Attenzione",
    "ALARMS_HOME": "Allarmi",
    "ALERT": "Attenzione",
    "ALL": "Tutti",
    "ALL_RIGHTS_RESERVED": "Tutti i diritti riservati",
    "ALL_DAYS": "Tutti",
    "ALL_ZONES": "Tutte",
    "ALWAYS_OFF": "Sempre spento",
    "ALWAYS_ON": "Sempre acceso",
    "APPLY_SCHEDULE": "Applica",
    "APPLY_SCHEDULE_TO": "Applica a",
    "APPLIED_TO": "Applicato a",
    "APPLY_SCHEDULE_TO_MSG": "Questa schedulazione è visibile e può essere applicata alle seguenti categorie",
    "ARS": "Unitá aria",
    "ASSIGN_NEW_NAME": "Rinomina",
    "ASSIGN_ZONE": "Assegna zone",
    "ASSIGN_ZONE_TEXT": "Edita nome alla macrozona",

    "AUTO": "Auto",
    "AUTO_ADAPT_COMFORT_TITLE": "Dettagli comfort auto adattativo",
    "AUTO_ADAPTATIVE_COMFORT_MODE": "Comfort auto-adattativo",
    "AUTO_ADAPTATIVE_COMFORT_OFF": "Comfort auto-adattativo OFF",
    "AUTO_ADAPTATIVE_COMFORT_ON": "Comfort auto-adattativo ON",
    "AUTO_C": "Auto",
    "AUTO_CHANGEOVER": "Cambio stagione automatico",
    "AUTO_CHANGEOVER_DIFF": "Delta T",
    "AUTO_CHANGEOVER_OVER_DIFF": "+ Delta T",
    "AUTO_CHANGEOVER_SUBTITLE": "Il sistema commuta automaticamente tra riscaldamento e raffrescamento per mantenere la temperatura della tua casa all'interno di uno specifico intervallo (+/- Delta T).",
    "AUTO_CHANGEOVER_UNDER_DIFF": "- Delta T",
    "HC_DIFF": "differenziali riscaldamento/raffrescamento",
    "DUAL_SETPOINTS_MODE":"(Beta) Setpoint duale",
    "AUX": "Ausiliari",
    "C_DIFF": "Differenziale raffrescamento",
    "H_DIFF": "Differenziale riscaldamento",
    "C_DIFF_SUBTITLE_1": "Questo differenziale di raffrescamento viene sommato al setpoint della zona per attivare ",
    "C_DIFF_SUBTITLE_2": "in raffrescamento per tenere la temperatura sotto setpoint + differenziale.",
    "H_DIFF_SUBTITLE_1": "Questo differenziale di riscaldamento viene sottartto al setpoint della zona per attivare ",
    "H_DIFF_SUBTITLE_2": "in riscaldamento per tenere la temperatura sopra setpoint - differenziale.",
    "AUTO_H": "Auto",
    "AVERAGE": "Avanzate",
    "BACKUP": "Backup",
    "BACK_TO_LOGIN": "torna all'accesso",
    "BACKUPS": "Backups",
    "BASIC_TEMPLATES": "Template predefiniti",
    "BIOSET_SETTINGS": "Impostazioni Bioset",

    "BLE_BLUETOOTH_DISABLED_TITLE": "Bluetooth disattivato",
    "BLE_BLUETOOTH_DISABLED_MESSAGE": "Il Bluetooth è stato disattivato. Attivalo e clicca su OK o torna alla selezione del sistema.",
    "BLE_BLUETOOTH_DISABLED_SCAN_MESSAGE": "Il Bluetooth è disattivato, attivalo per favore.",
    "BLE_CONNECT": "Connetti",
    "BLE_CONNECT_VIA_BLUETOOTH": "Connetti tramite Bluetooth",
    "BLE_CONNECTING_VIA_BLUETOOTH": "Connessione in corso...",
    "BLE_CONNECTING_VIA_BLUETOOTH_FULL": "Connessione in corso tramite Bluetooth...",
    "BLE_PERMISSION_DENIED": "Permessi per la posizione non autorizzate. Cambiali su autorizzazioni app del tuo dispositivo",
    "BLE_CONNECTED_VIA_BLUETOOTH": "Connesso tramite Bluetooth",
    "BLE_DISABLED_TOAST": "Il Bluetooth è disattivato. Attiva il Bluetooth.",
    "BLE_DISCONNECT_FROM_BLUETOOTH": "Disconnetti dal Bluetooth",
    "BLE_DISCONNECT_FROM_BLUETOOTH_MANUAL": "Vuoi disconnetterti dal Bluetooth? Se ti disconnetti, sarai reindirizzato alla pagina di selezione del sistema.",
    "BLE_DISCONNECTING_FROM_BLUETOOTH": "Disconnessione in corso...",
    "BLE_DISCONNECTED_FROM_BLUETOOTH": "Disconnesso dal Bluetooth",
    "BLE_EMERGENCY_TITLE": "Connessione remota non disponibile",
    "BLE_EMERGENCY_TEXT_BROWSER": "Nessuna connessione remota al tuo sistema.<br><br>Puoi utilizzare l'app Messana dal tuo smartphone e connetterti al tuo sistema tramite Bluetooth.",
    "BLE_EMERGENCY_TEXT_1": "Nessuna connessione remota al tuo sistema. Puoi provare a tornare alla",
    "BLE_EMERGENCY_TEXT_2": "oppure puoi utilizzare una connessione di emergenza Bluetooth rimanendo a meno di 5 metri dal tuo mBox.",
    "BLE_SYSTEM_SELECTION": "selezione del sistema",
    "BLE_LOGIN_PAGE": "pagina di login",
    "BLE_EMERGENCY_RESTORED_TITLE": "Connessione Internet ripristinata",
    "BLE_EMERGENCY_RESTORED_MESSAGE": "La tua connessione Internet è stata ripristinata. Vuoi riconnetterti o continuare a utilizzare il Bluetooth?",
    "BLE_EMERGENCY_RESTORED_ACTION_RECONNECT": "Riconnetti",
    "BLE_EMERGENCY_RESTORED_ACTION_KEEP": "Mantieni il Bluetooth",
    "BLE_EMERGENCY_RESTORED_POSTPONE": "Posticipa di 10 minuti",
    // "BLE_EMERGENCY_RESTORED_POSTPONE_TOAST": "Posticipato di 10 minuti",
    "BLE_LOW_RSSI_NOTIFICATION": "Sei troppo lontano dall'mBox, per favore avvicinati a meno di 5 metri",
    "BLE_OUT_OF_RANGE_UNSTABLE": "La connessione Bluetooth è instabile o fuori portata",
    "BLE_POPUP_DETAIL_TEXT": "Per favore, rimani nell'area di copertura (<5 m) e attiva il Bluetooth prima di connetterti. La connessione Bluetooth è disponibile solo su sistemi dotati di hardware Bluetooth.",
    "BLE_REQUEST_BLOCKED": "Impossibile gestire questa richiesta. Per favore, riprova.",
    "BLE_RETRY": "Riprova",
    "BLE_SAVE_FAILED": "Operazione non riuscita",
    "BLE_SAVE_FAILED_TEXT": "Il Bluetooth non è riuscito a eseguire questa richiesta. Vuoi riprovare?",
    "BLE_SCAN": "SCANSIONE",
    "BLE_UNAVAILABLE": "Bluetooth non disponibile",
    "BLE_UNAVAILABLE_TEXT": "Il dispositivo non è riuscito a trovare un server Bluetooth. Vuoi effettuare una nuova scansione?",

    "DEHUM_DIFF": "Differenziale semi-banda",
    "MAX_DEHUM": "Massima umidità relativa",
    "MIN_DEHUM": "Minima umidità relativa",
    "BUFFER_TANK_CONTROL_MODE": "Modalità di controllo accumulo",
    "BUFFER_TANK_COOLING_MAX_TEMP": "Temp sempre inferiore a",
    "BUFFER_TANK_COOLING_TARGET": "Setpoint in raffrescamento",
    "BUFFER_TANK_FIXED_POINT": "Punto fisso",
    "BUFFER_TANK_HEATING_TARGET": "Setpoint in riscaldamento",
    "BUFFER_TANK_SET": "Set temperatura accumulo",
    "BUFFER_TANK_SET_MENU": "Set temperatura accumulo",
    "BUFFER_TANK_TARGET": "Temperatura elaborata accumulo",
    "BUFFER_TANK_TARGET_MENU": "Temp elaborata accumulo",
    "BUFFER_TANK_TEMP": "Temp rilevata accumulo",
    "BUFFER_TANK_TEMP_MENU": "Temperatura di Accumulo",
    "BUFFER_TANKS": "Accumulo",
    "CANCEL": "Annulla",
    "MAIN_MENU": "Menu principale",
    "CELSIUS": "Celsius (C)",
    "CFM": "Piede cubico per minuto (cfm)",
    "CHANGE_PASSWORD": "Cambia password",
    "CHANGE_VALUE_FOR": "Cambia valore per",
    "CHANGING_MODE": "Cambiamento",
    "CHECK_CONNECTION": "Controlla connessione",
    "CONNECTION_TIMEOUT": "Connessione scaduta",
    "CONTACT": "Contattaci",
    "CHECK_PASSWORD": "Controlla password",
    "CITY": "Cittá",
    "CL_EXT_TEMP1": "1º punto - temperatura esterna",
    "CL_EXT_TEMP2": "2º punto - temperatura esterna",
    "CL_VALUE1": "1º punto - temperatura elaborata",
    "CL_VALUE2": "2º punto - temperatura elaborata",
    "CLOSE": "Chiudi",
    "COMFORT_RANGE": "Range di comfort",
    "COMMUNICATION_ERROR": "Errore di comunicazione",
    "M_TOUCH_COMMUNICATION_ERROR": "Errore di comunicazione mTouch",
    "CONDENSER_TEMP": "Temperatura condensatore",
    "CONDENSER_TEMPERATURE": "Temperatura condensatore",
    "CONFIGURATION": "Configurazione",
    "COP": "COP",
    "ADDRSS_CONFIGURATION": "Configurazione indirizzo IP",
    "HOSTNAME": "Hostname",
    "CONFIRM": "Conferma",
    "CONFIRM_AAC_CHANGE": "Sei sicuro? Potrebbe cambiare la stagionalitá",
    "CONFIRM_RESET_DEFAULT": "Sei sicur? Questa operazione cambierá tutti gli attributi.",
    "CONFIRM_SCHEDULE": "Diverse temperature selezionate. Sei sicuro di volere usare la temperature scelta?",
    "CONFIRM_SEASON": "Sei sicuro della modalità di cambio stagione scelta.",
    "CONFIRM_SEASON_MODE": "Questa operazione potrebbe durare alcuni minuti e non può essere interrotta. Vuoi continuare?",
    "CONFIRM_SEASON_AUTO_MODE": "Per funzionare in modalità Auto il sistema deve utilizzare i setpoint comuni e saranno applicate le impostazioni usate in riscaldamento così come le schedulazioni usate nelle zone per il riscaldamento verranno applicate anche alla modalità raffrescamento. Tutti i setpoint e le impostazioni di schedulazione in raffrescamento verranno persi. Questa operazione non è reversibile, potrebbe durare alcuni minuti e non può essere interrotta. Vuoi continuare?",
    "CONFIRM_SP_MODE": "Sei sicuro? Questa operazione cambierá i setpoint di tutte le zone.",
    "COOLING_MODE": "Raffrescamento",
    "COOLING_SUPPRESSD_1": "Richiesta di raffrescamento ignorata in",
    "COOLING_SUPPRESSD_2": "per una richiesta di riscaldamento concorrente nella stanza condivisa",
    "COPY_DAY": "Copia sched. giornaliera",
    "COPY_DAY_HEATING": "Copia sched. giorn. (risc.)",
    "COPY_DAY_COOLING": "Copia sched. giorn. (raffr.)",
    "COPY_DAY_TITLE": "Copia schedulazione giornaliera",
    "COPY_DAY_TO": "Copia la schedulazione sui seguenti giorni:",
    "COPY_OF": "Copia di",
    "COPYRIGHT": "Copyright © 2025 Messana Inc.",
    "COUNTRY": "Paese",
    "CREATE_CHART": "Crea grafico",
    "CREATE_TEMPLATE": "Crea Template",
    "DATE": "Data",
    "FILTER_USAGE": "Utilizzo filtro",
    "DIRTY_FILTER": "Filtro sporco",
    "DIRTY_FILTER_INFO": "Per pulire correttamente i filtri delle unità aria Messana, utilizzare un aspirapolvere o un compressore d'aria per aspirare o soffiare i detriti dal filtro. Sostituire il filtro solo se sono presenti evidenti segni di deterioramento. Per le unità aeree di terze parti, fare riferimento alle istruzioni del produttore.",
    "DAYS": "Giorni",
    "DAY_UNIT": "g",
    "HOUR_UNIT": "h",
    "MINUTE_UNIT": "m",
    "SECOND_UNIT": "s",
    "DEFINE_ZONE": "Definisce la stagionalità della zona",
    "DEHUMIDIFICATION": "Deumidificazione",
    "DEHUMIDIFICATION_INFO": "La deumidificazione è un processo in cui viene rimossa l'umidità in eccesso (calore latente). Poiché l'effetto della deumidificazione è il raffrescamento, in genere questa funzione è disponibile solo quando il sistema è impostato in modalità di raffrescamento. Nei sistemi di raffrescamento radiante, questa è una funzione essenziale per mantenere il punto di rugiada verso il basso ed evitare la condensa.",
    "CUSTOM_TEMPALTES_BTN": "Default TMP script",
    "DELETE": "Elimina",
    "DELETED": "eliminato",
    "DELETE_BACKUP_CONFIRM": "Sei sicuro di voler cancellare questo backup?",
    "DELETE_BLOCK": "Elimina Blocco",
    "DELETE_MACRO_ZONE": "Elimina macrozona",
    "DELETE_PERIOD": "Elimina periodo giornaliero",
    "DELETE_SCHEDULATION": "Sei sicuro di cancellare la schedulazione ",
    "DELETE_SCHEDULATION_INTERVAL": "Elimina periodo schedulazione",
    "DELETE_TEMPLATE": "Elimina Template",
    "DELTA_HC": "Differenziale tra setpoints H e C",
    "DEW_POINT": "Punto di rugiada",
    "DHW": "Acqua calda sanitaria",
    "DHW_DIFF_OFF": "Differenziale off",
    "DHW_DIFF_ON": "Differenziale on",
    "DHW_DIFF_DESC_OFF": "La richiesta ACS si interrompe al raggiungimento della temperatura serbatoio ACS",
    "DHW_DIFF_DESC_ON_1": "Quando la temperatura dell'acqua scende al di sotto di",
    "DHW_DIFF_DESC_ON_2": "attiva la richiesta dell'ACS per portare la temperatura dell'acqua fino a",
    "DHW_DIFF_DESC_ON_3": "(temperatura target).",
    "DHW_RECIRCULATION_ON": "Ricircolo ON",
    "DHW_RECIRCULATION_SET": "Set di ricircolo",
    "DHW_RECIRCULATION_SET_MENU": "Set di ricircolo",
    "DHW_RECIRCULATION_TEMP": "Temperatura di ricircolo",
    "DHW_RECIRCULATION_TEMP_MENU": "Temperatura di ricircolo impianto sanitario",
    "DHW_SET_TEMP": "Set point acqua calda sanitaria",
    "DHW_SET_TEMP_MENU": "Set point acqua calda sanitaria",
    "DHW_SETBACK": "Attenuazione ACS",
    "DHW_SETBACK_SETPOINT": "Setpoint di attenuazione",
    "DHW_SETBACK_SETPOINT_DESCRIPTION": "Durante il periodo fuori orario il serbatoio dell'ACS viene mantenuto alla temperatura del setpoint di attenuazione di",
    "DHW_SETPOINT_BELOW_WARNING": "Setpoint inferiore alla temperatura target dell'ACS",
    "TNK_SETPOINT_BELOW_WARNING": "Setpoint inferiore alla temperatura target del serbatoio di accumulo",
    "TNK_SETPOINT_ABOVE_WARNING": "Setpoint superiore alla temperatura target del serbatoio di accumulo",
    "DHW_TARGET_TEMP": "Temp. elaborata ACS",
    "DHW_TEMP": "Temperatura acqua calda sanitaria",
    "DHW_TEMP_MENU": "Temperatura acqua calda sanitaria",
    "DHW_TEMP_SCHEDULE_ON": "Acqua calda sanitaria non disponibile al di fuori dei periodi schedulati.",
    "DHW_SYSTEMS": "Sistemi ACS",
    "DIFF_COMP": "Differenziale ACS",
    "DIFF_COMP_C": "Differenziale raffrescamento",
    "DIFF_COMP_H": "Differenziale riscaldamento",
    "DIFF_LEVEL": "Differenziale",
    "DIFF_LEVEL_C": "Differenziale al 100%",
    "DIFF_LEVEL_H": "Differenziale al 100%",
    "DIFFERENT_SCHEDULATION": "Schedulazione differente",
    "DISABLE_SCHEDULE": "Disabilita",
    "DISCONNECT": "Disconnetti",
    "DISCONNECT_TITLE": "Disconnessione",
    "DISCONNECT_CONFIRM": "Sei sicuro di volerti disconnettere dall'attuale sistema?",
    "DISCONNECT_BUTTON": "Disconnetti",
    "DISABLED": "Disabilitato",
    "NO_SCHED_SELECTED": "Nessuna sched. selezionata",
    "NO_SCHEDULE": "-- Nessuna schedulazione --",
    "NO_USER_FOUND": "Nessun utente trovato con il nome utente/indirizzo e-mail fornito.",
    "DISCARD": "Scarta",
    "DOMESTIC_HOT_WATER_SYSTEMS": "Impianto sanitario",
    "DUPLICATE": "Duplica",
    "EDIT_DAY": "Modifica schedulazione giornaliera",
    "EDIT_SCHEDULE": "Modifica schedulazione",
    "EDIT_TEMPLATE_PAGE": "Edita template",
    "EMPTY_NAME": "Nome non specificato",
    "ENERGY_SAVING": "Risparmio energetico",
    "ENERGY_SAVING_DIFFERENTIAL": "Differenziale per il risparmio energetico",
    "TEMPERATURE_ENERGY_SAVING_DIFFERENTIAL": "Diff. temp. risparmio energetico",
    "HUMIDITY_ENERGY_SAVING_DIFFERENTIAL": "Diff. um. risparmio energetico",
    "ENGLISH": "Inglese",
    "ENR": "Risorse Riscaldamento/Raffrescamento",
    "ENV_TEMP": "Temp amb.",
    "ENVIRONMENT_TEMP": "Temp aria fresca",
    "ENVIRONMENT_TEMPERATURE": "Temperatura ambiente",
    "ERR_CODE": "Usa mConfig per caricare la configurazione",
    "ERR_CODE_TITLE": "Dispositivo non configurato",
    "ERR_CODE_TITLE_UPDATE": "ATTENZIONE",
    "ERR_CODE_UPDATE": "Il tuo sistema richiede la nuova versione dell'app",
    "EVAPORATION_TEMP": "Temperatura evaporatore",
    "EVAPORATION_TEMPERATURE": "Temperatura evaporatore",
    "EVERY_DAY": "ogni giorno",
    "EVERY_2_DAYS": "ogni 2 giorni",
    "EVERY_WEEK": "ogni settimana",
    "EX": "Esempio: ",
    "EXCEPTION_KEEP": "Mantieni",
    "EXCEPTION_MESSAGE": "Vuoi sovrascrivere le eccezioni?",
    "EXCEPTION_OVERRIDE": "Sovrascrivi",
    "EXCEPTION_TITLE": "Eccezioni",

    "EXTEND_SCHEDULE": "Estendi",
    "EXTEND_SCHEDULE_MESSAGE": "Estendi la schedulazione ad altra stagionalitá?",
    "EXTERNAL_TEMP": "Temp aria frasca",
    "EXTERNAL_TEMPERATURE": "Temperatura esterna",
    "FAHRENHEIT": "Fahrenheit (F)",
    "FAN_COIL_MODE_C": "Modalitá (Raffrescamento)",
    "FAN_COIL_MODE_H": "Modalitá (Riscaldamento)",
    "FAN_COILS": "Fan Coils",
    "FAN_SPEED": "Velocità della ventola",
    "FILTER_ALARM_RESET": "Azzera",
    "DIRTY_FILTER_INFO_START": "Il filtro è esausto, per favore premi il bottone",
    "DIRTY_FILTER_INFO_END": "dopo che hai pulito o sistituito il filtro.",
    "FILTER_DELAY": "Intervallo pulizia filtri",
    "FLOW_LEVEL": "Portata in rinnovo",
    "FLOW_RATE": "Impostazioni portata d'aria",
    "FLOW_UNITS": "Unitá portata d'aria",


    "MCU": "Unità di Controllo Messana",
    "ATU": "Unitá trattamento aria",
    "EXP": "Modulo espansione",
    "FNC": "Fan coil",
    "FLS": "Sensore di flusso",
    "FWA": "Scaldapavimento",
    "HYS": "Impianto idraulico",
    "FNC_INTEGRATION": "Integrazione Fan Coil",
    "FNC_HEATING_INTEGRATION": "Integrazione riscaldamento",
    "FNC_COOLING_INTEGRATION": "Integrazione raffrescamento",
    "FOLLOW_HEATING_COOLING_LOADS": "segui i caricarimenti pre-esistenti",
    "FOLLOWING_ZONE": "Segui la zona",
    "FORCED": "forzato",
    "FORGOT_PASSWORD": "Password dimenticata?",
    "SIGN_IN": "Accedi",
    "FREE_COOLING": "Free cooling",
    "FREE_COOLING_ACTIVATION_LIMIT": "Limite attivazione free cooling",
    "FREE_COOLING_END_LIMIT": "Limite disattivazione free cooling",
    "FROM": "Da",
    "FULL_ADDRESS": "Indirizzo completo",
    "GENERAL_SETTINGS": "Impostazioni generali",
    "GO_CUSTOM_TEMPALTES": "Modifica template di systema",
    "GO_SETTINGS": "Selezione param/data",
    "GO_ADVANCED_SETTINGS": "Impostazioni avanzate",
    "GO_TEMPLATES": "Templates",
    "GO_TO": "Vai su ",
    "GUEST": "Ospite",
    "GRAPH_PAGE": "Storici",
    "SHOW_POINTS": "Mostra punti",
    "INTERPOLATION": "Interpolazione",
    "LINEAR": "Interpolazione lineare",
    "MONOTONE": "Spline cubica",
    "LINEAR-CLOSED": 'Linear-closed',
    "BASIS": 'B-spline',
    "BASIS-OPEN": 'Basis-open',
    "BASIS-CLOSED": 'Basis-closed',
    "BUNDLE": 'Bundle',
    "CARDINAL": 'Cardinal',
    "CARDINAL-OPEN": 'Cardinal-open',
    "CARDINAL-CLOSED": 'Cardinal-closed',
    "GRP": "Gruppo",
    "H": "Riscaldamento",
    "C": "Raffrescamento",
    "H_SLASH_C": "Risc./Raff.",
    "H_SHORT": "C",
    "C_SHORT": "F",
    "H_AND_C_SHORT": "C & F",
    "H_C_SOURCE_RETURN_TEMP": "Temperatura di ritorno",
    "H_C_SOURCES": "Sorgenti energetiche",
    "H_C_SOURCES_DELIVERY_TEMP": "Temperatura di mandata",
    "HEAT_PUMPS": "Pompe di calore",
    "ENERGY_SOURCES": "Risorse energetiche",
    "HEAT_PUMPS_TARGET_TEMP": "Temperatura target",
    "HEAT_PUMPS_TARGET_TEMP_HEATING": "Temperatura target riscaldamento",
    "HEAT_PUMPS_TARGET_TEMP_COOLING": "Temperatura target raffrescamento",
    "HEAT_PUMP_CONTROL_MODE_H_C": "Modalità di controllo pompa di calore H/C",
    "HEAT_PUMP_CONTROL_MODE_DHW": "Modalità di controllo pompa di calore DHW",
    "HP_FIXED_TARGET_TEMP": "Punto fisso",
    "HP_FOLLOW_BUFFER_TANK": "Segui il serbatoio di accumulo",
    "HP_FOLLOW_DHW": "Segui la temperatura target ACS",
    "HP_CONTROL_PARAMETERS": "Parametri di controllo",
    "HP_DIFF_COMP_DHW_SUBLABEL": "Questo differenziale viene aggiuntao alla temperatura target per l'ACS",
    "HP_STATE_PARAMETERS": "Parametri di stato",
    "HEAT_RECOVER_VENTILATION": "Rinnovo con recupero di calore",
    "HEAT_RECOVER_VENTILATION_ES": "Risparmio energetico",
    "HEAT_RECOVER_VENTILATION_ES_SUBTITLE": "Non usa risorse energetiche durante il rinnovo con recupero di calore",
    "HEAT_RECOVER_VENTILATION_ES_SUBTITLE_MOBILE": "Non usa risorse energetiche in rinnovo",
    "HEATING_AND_COOLING": "Riscaldamento e raffrescamento",
    "HEATING_MODE": "Riscaldamento",
    "HIGH": "Alto",
    "HIGHTER": "Piú alto",
    "HISTORY": "Storici",
    "HISTORY_NOT_FOUND": "Dati storici per i grafici non trovati",
    "HISTORY_PAGE": "Selezione param/data",
    "HOME": "Home",
    "day": "giorno",
    "days": "giorni",
    "hour": "ora",
    "hours": "ore",
    "min": "min",
    "minute": "minuto",
    "minutes": "minuti",
    "Hour": "Ora",
    "HUMIDIFICATION": "Umidificazione",
    "HUMIDIFICATION_INFO": "L'umidificazione è un processo in cui viene aggiunta l'umidità dell'aria (calore latente). In genere questa funzione è disponibile solo quando il sistema è impostato in riscaldamento durante l'inverno quando l'umidità esterna è bassa.",
    "HUMIDITY": "Umidità",
    "REL_HUMIDITY": "Umidità rel.",
    "RELATIVE_HUMIDITY": "Umidità relativa",
    "HYDRONIC_SYSTEM_TARGET": "Temperatura elaborata",
    "HYDRONIC_SYSTEMS": "Impianto idronico",

    "IN_OUT_SPEED_MESSAGE": "To avoid depressurization set the exhaust fan speed lower than supply speed",
    "IN_SPEEDS": "Velocitá portata in entrata",
    "INLET_FLOW": "Portata in entrata",
    "INTEGRATION": "Integrazione",
    "INTEGRATION_OFF": "Spenta a",
    "INTEGRATION_ON": "Aaccesa a",
    "INTERNET_CONNECTION_RESTORED": "Internet connection restored",
    "INTERNET_CONNECTION_UNAVAILABLE": "Internet connection unavailable",
    "INTERVAL_TYPE": "Tipo intervallo",
    "INVALID_IP_ADDRESS": "Indirizzo IP non valido",
    "INVALID_HOSTNAME": "I caratteri validi per gli hostname sono le lettere dalla a alla z, le cifre da 0 a 9 e il trattino (-). Un hostname non può iniziare con un trattino.",
    "IP_ADDRESS": "Indirizzo IP",
    "VPN_IP_ADDRESS": "Indirizzo IP VPN",
    "MAC_ADDRESS": "Indirizzo MAC",
    "IP_ADDRESS_IS_REQUIRED": "L'indirizzo IP è richiesto",
    "HOSTNAME_IS_REQUIRED": "L'hostname è richiesto",
    "ITALIAN": "Italiano",
    "KEEP_SCHEDULE": "Mantieni",
    "KEEP_ZOOM": "fissa lo zoom",
    "KEEP_SETPOINTS": "Mantieni",
    "LANGUAGE": "Lingua",
    "LAN_DISCOVERY_TITLE": "Ricerca locale controlli",
    "LOCAL_CONNECT": "Connessione locale",
    "LOCATION_KEY_SENTENCE": "La chiave della tua località è il codice che trovi nella barra degli indirizzi del browser",
    "LOGIN_EMAIL_LABEL": "Email",
    "LOGIN_ERROR_SUBTITLE": "Email o password non valide",
    "LOGIN_ERROR_TITLE": "Login fallito!",
    "LOGIN_PAGE_TITLE": "Messana Login",
    "LOGIN_PASSWORD_LABEL": "Password",
    "LOGIN_USERNAME_LABEL": "Username",
    "LOGOUT": "Esci",
    "LOGOUT_CONFIRM": "Sei sicuro di voler uscire dall'account",
    "LOW": "Basso",
    "LOWER": "Piú basso",
    "M3H": "Metri cubici per ora (m³/h)",
    "MACRO_ZONES": "Macrozone",
    "MACROZONE_NAME": "Nome macrozona",
    "MAIN_GRP": "Gruppo principale",
    "RELATED_GRP": "Gruppo Associato",
    "MANUAL": "Manuale",
    "Manual": "Manuale",
    "MANUAL_C": "Manuale",
    "MANUAL_CHANGEOVER": "Cambio stagione manuale",
    "MANUAL_CHANGEOVER_SUBTITLE": "L'utente decide quando cambiare tra riscaldamento e raffrescamento a seconda delle sue esigenze.",
    "MANUAL_H": "Manuale",
    "MANUAL_LEVEL": "Livello manuale",
    "MANUAL_LEVEL_C": "Livello manuale",
    "MANUAL_LEVEL_H": "Livello manuale",

    "MANUALLY": "Manuale",
    "MAX_IN_SPEED": "Velocitá MAX",
    "MAX_OUT_SPEED": "Velocitá MAX",
    "MAX_OUTB_SPEED": "Velocitá MAX",
    "MAX_SPEED": "Velocitá MAX",
    "MAX_SUPPLY_TEMP": "Massima temperatura di mandata (Risc)",
    "MAX_SUPPLY_TEMP_MOBILE": "Max temp mandata",
    "MAX_SUPPLY_TEMP_DESC":"Temperatura obiettivo massima del sistema idronico quando il riscaldamento è eseguito da un pavimento o soffitto radiante.",
    "MCU_CONNECT_OLD": "Vai al service.radiantcooling.com",
    "MCU_SELECT_LABEL": "Seleziona il tuo impianto",
    "MCU_SELECT_PAGE_TITLE": "Seleziona il tuo impianto",
    "MCZ": "Macrozona",
    "MCZ_USED_NAME": "Questo nome è già usato",
    "TMP_USED_NAME": "Questo nome è già usato",
    "MEDIUM": "Mediano",
    "MIN_IN_SPEED": "Velocitá MIN",
    "MIN_OUT_SPEED": "Velocitá MIN",
    "MIN_OUTB_SPEED": "Velocitá MIN",
    "MIN_SUPPLY_TEMP": "Minima temperatura di mandata (Raff)",
    "MIN_SUPPLY_TEMP_MOBILE": "Min temp mandata",
    "MIN_SUPPLY_TEMP_DESC":"Temperatura obiettivo minima del sistema idronico quando il raffrescamento è eseguito da un pavimento o soffitto radiante.",
    "RADIANT_SECTION":"Impostazioni sistema idronico radiante",
    "MIXING_VALVE": "Valvola miscelatrice",
    "MXV": "Miscelatrice",
    "MY_TEMPLATES": "Miei Template",
    "NETWORK": "Rete",
    "NETWORK_DEVICE_INFO": "Info periferica",
    "RELOADING_NETWORK": "Riavvio la rete, attendi per favore",
    "NETWORK_SETTINGS": "Impostazioni di rete",
    "NETWORK_UPDATE": "Impostazioni di rete aggiornate",
    "NEVER": "mai",
    "Never": "Mai",
    "NEW_BACKUP": "Crea nuovo backup",
    "NEW_BACKUP_TEXT": "Assegna un nome al backup",
    "NEW_MACRO_ZONE": "Nuova macrozona",
    "NEW_MACRO_ZONE_SELECT_GROUP": "Seleziona un gruppo per la macrozona",
    "NEW_MACRO_ZONE_TEXT": "Scrivi il nome della nuova macrozona",
    "NEW_SCHEDULE": "Nuova schedulazione",
    "NO_SCH_IN_SYSTEM": "Questo sistema non ha schedulazioni, è necessario crearne una nuova prima di applicarla.",
    "NEW_SCHEDULE_FROM_DUPLICATE": "Assegna un nome alla schedulazione duplicata",
    "NEW_SCHEDULE_PROFILE": "Nuovo profilo di schedulazione",
    "NEW_SCHEDULE_PROFILE_TEXT": "Assegna un nome e un tipo al nuovo profilo di schedulazione",
    "NEW_SCHEDULE_SEASON_TEXT": "Assegna un nome al nuovo profilo di schedulazione",
    "NEW_EMA_PROFILE": "Nuovo profilo di segnalazioni e-mail",
    "NEW_EMA_PROFILE_TEXT": "Assegna una e-mail (obbligatorio) ed un nome al nuovo profilo di segnalazione e-mail",
    "NO_INTERNET_POPUP_MESSAGE": "Non hai una connessione internet, o la connessione all'mBox non è disponibile.",
    "NO_INTERNET_POPUP_TITLE": "Connessione non disponibile",
    "EMA_DELETE_MESSAGE": "Desideri eliminare questo profilo di avviso e-mail?",
    "EMA_EMAIL_INVALID": "E-mail invalida",
    "EMA_EMAIL": "E-mail segnalazione (obbligatorio)",
    "EMA_NAME": "Nome segnalazione",
    "EMAIL_ALERTS": "Segnalazioni e-mail",
    "TITLE_EMA_PAGE": "Profili di segnalazioni e-mail",
    "DETAILS_EMA_PAGE": "Configurazione segnalazione e-mail",
    "EMA_SETTINGS": "Impostazioni segnalazione",
    "EMA_EMAIL_ADDRESS": "Indirizzo e-mail",
    "EMA_RECIPIENT": "Nome destinatario",
    "EMA_ON_EVENT": "Invio sul sorgere dell'evento",
    "EMA_ACTIVE": "Attivo",
    "EMA_DELIVERY_FREQUENCY": "Frequenza del report",
    "EMA_PERIODIC_DESCRIPTION" :"L'e-mail periodica viene inviata ",
    "EMA_PERIODIC_DESCRIPTION_NEVER":"L'invio periodico dell'e-mail è disattivato.",
    "EMA_ACTIVE_DESCRIPTION":"Il profilo di e-mail di allerta è attivo.",
     "EMA_EVENT_HAPPENS_DESCRIPTION":"L'e-mail di segnalazione viene inviata quando si verifica l'evento.",
    "NEW_TEMPLATE": "Nuovo Template",
    "NEW_TEMPLATE_PAGE": "Nuovo Template",
    "NEW_TIMER": "Nuovo timer",
    "NO": "No",
    "NO_DATA": "Chiavi non disponibili",
    "NO_DEVICE": "Nessuna periferica è stata trovata.",
    "NOT_AVAILABLE": "Non Disponibile",
    "NO_RELATED_ROOMS": "Nessuna stanza associata",
    "NO_SERVED_ROOMS": "Nessuna stanza servita",
    "NO_RELATED_ENTITIES": "Impianto non usato",
    "NO_RESULT_DATA": "Dati non disponibili",
    "NOT_SUPPORTED_MESSAGE": "Non ancora supportato",
    "ODS": "Sensore esterno",
    "OFF": "Spento",
    "OLD_MCU_ROW1": "É stato selezionato un sistema non supportato perché basato sulla precedente versione di unità di controllo",
    "OLD_MCU_ROW2": "Per accedere al sistema si prega di effettuare il login.",
    "OLD_MCU_ROW3": "Se siete interessati ad aggiornare il Vostro sistema, vi preghiamo di contattarci.",
    "ON": "Acceso",
    "ON_ABOVE": "Acceso a sopra di",
    "ON_ABOVE_STATIC_TEXT": "Acceso a sopra del setpoint +",
    "ON_BELOW": "Acceso a sotto di",
    "ON_BELOW_STATIC_TEXT": "Acceso a sotto del setpoint -",
    "ON_OFF_POINT": "On/Off point",
    "ON_PARTIALIZED": "On - funzione termica disattivata e sistema aria parzializzato",
    "ON_SCHEDULING_WITH_FREE_COOLING_REQUEST_TO_AHU": "Schedulato con richiesta di free cooling alla AHU",
    "ON_SCHEDULING_WITH_HRV_REQUEST_TO_AHU": "Schedulato con richiesta HRV alla AHU",
    "ON_THERMAL_DEACTIVATED_AIR_OFF": "ON - funzione termica disattivata e sistema aria spento",
    "ON_THERMAL_DECTIVATED": "ON - solo funzione termica disattivata",
    "ON_ZONE_SCHEDULING": "Su schedulazione zone",
    "ONE_DAY": "1 Giorno",
    "SEARCH": " e cerca la tua località (cap/paese)",
    "SEARCHING_ZEROCONF_DEVICES": "Sto cercando dispositivi locali..",
    "SEARCHING_ZEROCONF_DEVICE_FOUND": "mBox trovato nella rete locale",
    "SEARCHING_ZEROCONF_DEVICE_FOUND_TEXT_1": "Un nuovo dispositivo locale è stato trovato. Info:",
    "SEARCHING_ZEROCONF_DEVICE_FOUND_TEXT_2": "Vuoi connetterti a questo dispositivo?",
    "ZEROCONF_DEVICE_LOADING": "Caricamento del dispositivo locale..",
    "SEVEN_DAYS": "7 Giorni",
    "FOURTEEN_DAYS": "14 Giorni",
    "ONE_WEEK": "Settimanale",
    "OK": "Ok",
    "TWO_WEEKS": "Bisettimanale",
    "MONTH": "Mensile",
    "OPEN_WINDOW_LOGIC": "Logica finestra aperta",
    "OPERATIVE": "Operativa",
    "OPERATIVE_MODES": "Modalità operativa",
    "OUT_OF_SCHEDULE": "Fuori fascia",
    "OUT_SPEEDS": "Velocitá aria di scarico",
    "OUTB_SPEEDS": "Velocitá aria di scarico bagno",
    "OUTDOOR_TEMPERATURE_COMPENSATION": "Retta di compensazione",
    "OUTLET_FLOW": "Aria di scarico",
    "OUTLET_FLOW_BATH": "Aria di scarico bagni",
    "OVERRIDE_SETPOINTS": "Sovrascrivi",
    "OVEERRIDE": "Scavalca",
    "OVERRIDE_SETPOINT": "Override setpoint",
    "OVERRIDE_SETPOINT_HEATING": "Override setpoint riscaldamento",
    "OVERRIDE_SETPOINT_COOLING": "Override setpoint raffrescamento",
    "OVERRIDE_SETPOINT_TITLE": "Impostazioni override",
    "OVERRIDE_SET_TIME": "Imposta la durata dell'override",
    // "OVERRIDE_SETPOINT_PERIOD_TITLE": "Time span",
    "OVERRIDE_SETPOINT_PERIOD_TEXT_1": "Il nuovo setpoint a",
    "OVERRIDE_SETPOINT_PERIOD_TEXT_2": "verrà applicato per una durata di",
    "OVERRIDE_SETPOINT_PERIOD_TEXT_3": "indipendentemente dalla schedulazione. Poi il setpoint tornerà a",
    "OVERRIDE_TEMP_SETPOINT": "Temperatura del setpoint",
    "PAR_HISTORY": "Parametri",
    "PMP": "Pompa",
    "PRIMARY_DNS": "DNS Primario",
    "PRIVACY_POLICY": "Informativa sulla Privacy",
    "CONTACT_SUPPORT": "Contatta il supporto",
    "KNOWLEDGE_BASE": "Knowledge base",
    "HELP": "Assistenza",
    "PRS": "Sensore di pressione",
    "PUMP_STATUS": "Stato pompa",
    "RADIANT": "Radiante",
    "RANGE_ERROR": "Valore fuori dall'intervallo",
    "RANGE_VALOR": "Per favore inserisci il valore nell'intervallo",
    "REBOOT": "Riavvia il sistema",
    "REBOOT_CONFIRM": "Sei sicuro di riavviare il sistema?",
    "REBOOT_ONGOING": "Riavvio in corso...",
    "RELATED_ROOM": "Zona associata",
    "RELATED_ROOMS": "Zone associate",
    "SERVED_ROOMS": "Zone servite",
    "SERVED_ROOM": "Zona servita",
    "SERVER_IS_DOWN": "Per motivi di manutenzine non saremo disponibili per un po'.<br><br>Ritorna più tarti, grazie",
    "STARTUP": "Avviamento",
    "NO_RELATED_FANCOILS": "Nessun fancoil associato",
    "RELATED_ATUS": "Macchine aria associate",
    "RELATED_ATU": "Macchina aria associata",

    "NO_RELATED_ATU": "Nessuna macchina aria associata",
    "RELATED_FANCOILS": "Fancoil associati",
    "REL_FANCOILS": "Fancoil assoc.",
    "RELATED_FANCOIL": "Fancoil associato",
    "NO_RELATED_FANCOIL": "Nessun fancoil associato",
    "REL_FANCOIL": "Fancoil assoc.",

    "RELATED_TANKS": "Accumuli associati",
    "RELATED_TANK": "Accumulo associato",
    "NO_RELATED_TANK": "Nessun accumulo associato",

    "RELATED_AIR_UNITS": "Impianti aria associati",
    "RELATED_AIR_UNIT": "Impianto aria associato",
    "NO_RELATED_AIR_UNITS": "Nessun impianto aria associato",

    "RELATED_ARSS": "Sistemi aria associati",
    "RELATED_ARS": "Sistema aria associato",
    "NO_RELATED_ARS": "Nessun sistema aria associato",

    "NO_RELATED_HYDRONICSYSTEM": "Nessun sistema idraulico associato",
    "RELATED_HYDRONICSYSTEM": "Sistema idraulico associato",
    "RELATED_HYDRONICSYSTEMS": "Sistemi idraulici associati",

    "RELATIVE": "Relativa",
    "REMOVE_EXCEPTIONS": "Cancella eccezioni",
    "REMOVE_EXCEPTIONS_TEXT": "Sei sicuro di voler cancellare le eccezioni?",
    "RENAME": "Rinomina",
    "RENAME_MACRO_ZONE": "Rinomina macrozona",
    "RENAME_MACRO_ZONE_TEXT": "Assegnazione nome macrozone",
    "RENAME_SCHEDULATION_TEXT": "Assegna un nome al profilo di schedulazione",
    "RENAME_SCHEDULE": "Rinomina",
    "RENAME_TEXT": "Assegna un nuovo nome",
    "RENAME_TIMER": "Rinomina timer",
    "RENAME_TIMER_TEXT": "Assegna un nome al timer",
    "RENAME_ZONE": "Rinomina zona",
    "RENAME_ZONE_TEXT": "Edita nome alla zona",
    "REORDER_MACRO_ZONES": "Riordina macrozone",
    "REORDER_ZONES": "Riordina zone",
    "RESET": "Reset",
    "RESET_HEATING": "Reset riscaldamento",
    "RESET_COOLING": "Reset raffrescamento",
    "RESET_ALARM_WARNING_POPUP_TEXT": "Sei sicuro di voler resettare i tuoi",
    "RESET_ERROR_TITLE": "Errore",
    "RESET_OVERRIDE_TITLE": "Cancella Override",
    "RESET_OVERRIDE_MESSAGE": "Vuoi cancellare l'Override corrente?",
    "RESET_USER_MAIL_LABEL": "nome utente/indirizzo e-mail",
    "RESET_TROUBLE": "Non ricordi la password?",
    "RESET_SOLUTION": "Non preoccuparti, inserisci il tuo nome utente/indirizzo e-mail e ti invieremo una e-mail con le istruzioni per reimposgtare la tua password",
    "RESET_SENT_TITLE": "E-mail di ripristino invata!",
    "RESET_SENT_MESSAGE": "Un collegamento per reimpostare la tua password è stato inviato a ",
    "RESET_DAY_SCHEDULE": "Reset sched. giornaliera",
    "RESET_DAY_SCHEDULE_HEATING": "Reset sched. giorn. (risc.)",
    "RESET_DAY_SCHEDULE_COOLING": "Reset sched. giorn. (raffr.)",
    "RESET_DEFAULT": "Ripristino a valori di default",
    "RESET_SCHEDULATION": "Sei sicuro di voler resettare la schedulazione?",
    "RESTART": "Riavvio servizi",
    "RESTART_CONFIRM": "Sei sicuro di voler riavviare le logiche?",
    "RESTART_ONGOING": "Riavvio servizi in corso...",
    "RESET_ALARMS_TOAST": "Reset in corso..",
    "RESTORE_BACKUP": "Ripristina",
    "RESTORE_BACKUP_CONFIRM": "Sei sicuro di voler ripristinare questo backup?",
    "RETURN_TEMP": "Temperatura di ritorno",
    "WATER_RETURN_TEMP": "Temperatura di ritorno",
    "RETURN_TODAY": "Ritorna ad oggi",
    "RMS": "Zone",
    "ROOM_AIR_SUPPLY_RH": "Umidità aria in ingresso RH",
    "ROOM_AIR_SUPPLY_DP": "Umidità aria in ingresso DP",
    "ROOM_AIR_SUPPLY_RH_HUM": "Aria in ingresso RH",
    "ROOM_AIR_SUPPLY_DP_HUM": "Aria in ingresso DP",
    "ROOM_AIR_SUPPLY_TEMP": "Temp aria in ingresso",
    "ROOM_AIR_SUPPLY_TEMPERATURE": "Temperatura aria in ingresso",
    "ROOM_OFF": "Spenta",
    "ROOM_ON": "Accesa",
    "ROUTER": "Router",
    "RTU_HISTORY": "Dati in tempo reale",
    "SAME_SCHEDULE_MESSAGE": "Mantieni la stessa schedulazione per le stagionalitá?",
    "SAN_F": "La chiave della località San Francisco è ",
    "SAVE": "Salva",
    "SAVE_GO": "Salva e vai",
    "SAVE_TEMPLATE": "Salva Template",
    "SCH": "Schedulazione",
    "SCH_PREVIEW": "Mostra/Nascondi schedulazione",
    "SEASON_SCHEDULE": "Schedulazione stagionale",
    "SCHEDULE": "Schedulazione",
    "SCHEDULE_CHANGEOVER": "Schedulazione cambio stagione",
    "SCHEDULE_CHANGEOVER_DISABLED": "Nessuna schedulazione trovata",
    "SCHEDULE_CHANGEOVER_SUBTITLE": "Il sistema cambia stagione tra riscaldamento e raffrescamento a seconda della schedulazione settimanale.",
    "SCHEDULE_DISABLED": "disabilitata",
    "SCHEDULE_DYNAMIC": "Schedulazione dinamica",
    "SCHEDULE_NAME": "Nome schedulazione",
    "SCHEDULE_PROFILES": "Profili di schedulazione",
    "SCHEDULE_SEASON_CHANGE": "Schedulazione cambiata",
    "SCHEDULE_SEASON_CHANGE1": "Applica questa schedulazione '",
    "SCHEDULE_SEASON_CHANGE2": "' alla stagionalitá ",
    "SCHEDULE_SEASON_CHANGE3": "",
    "SCHEDULE_SEASON_DISABLED": "Disabilita la schedulazione nella stagionalitá ",

    "SCHEDULE_TYPE": "Tipo schedulazione",
    "SCHEDULE_TYPE_ON_OFF": "On/Off general",
    "SCHEDULE_TYPE_ON_OFF_DESCRIPTION": "Temperature e timer",
    "SCHEDULE_TYPE_SEASON": "Stagionalità",
    "SCHEDULE_TYPE_VARIABLE": "Temperatura variabile",
    "SCHEDULE_TYPE_DUAL_VARIABLE":"Temp. var duale",
    "SCHEDULE_TYPE_VARIABLE_SHORT": "Temp. variabile",
    "SCHEDULE_TYPE_DUAL_VARIABLE_SHORT":"Temp. variabile duale",
    "SEASON_CHANGEOVER": "Cambio stagione",
    "SEASON_CHANGING": "Cambio stagione",
    "SEASON_MODE": "Modalitá stagione",
    "SECONDARY_DNS": "DNS secondario",
    "SELECT_NEW_DATA": "Lista chiavi",
    "SELECT_TERMINAL_RENAME": "Seleziona il Terminale da rinominare",
    "SELECT_TIMER_RENAME": "Seleziona il Timer da rinominare",
    "SET_DEW_POINT": "Set punto di rugiada",
    "DEW_POINT_TEMP": "Punto di rugiada",
    "SET": "Set",
    "SET_HUMIDITY": "Set umidità",
    "SET_TEMP_AIR_IN_C": "Setpoint mandata aria (C)",
    "SET_TEMP_AIR_IN_H": "Setpoint mandata aria (H)",
    "SET_TEMPERATURE": "Set",
    "SET_TIME": "Impostato tempo",
    "SETBACK": "Attenuazione",
    "SETBACK_DIFFERENTIAL": "Differenziale di attenuazione",
    "SETPOINT_MODE": "Setpoint temperature delle zone",
    "SETPOINT_SEASONALLY": "Setpoint duali",
    "SETPOINT_UNIQUE": "Setpoint comuni",
    "SETTINGS": "Impostazioni",
    "SETUP": "Imposta",
    "SHOW_SETTINGS": "Impostazioni",
    "SHOW_TEMPLATE_PAGE": "Mostra Template",
    "SHOW_TODAY_TEMPS": "Mostra le temperature di Oggi",
    "SHOW": "Mostra",
    "HIDE": "Nascondi",
    "SHT": "Shutter",
    "SLEEP_MODE": "Modalitá Notte",
    "SLEEP_MODE_FROM": "Da",
    "SLEEP_MODE_TO": "a",
    "SPEED_LOW_LIMIT": "Fan speed at half speed down to 300",
    "SPEED":"Velocità",
    "STANDARD": "Standard",
    "STATE": "Stato",
    "STATUS": "Stato",
    "SUBNET_MASK": "Subnet mask",
    "SUPPLY_TEMP": "Temperatura di mandata",
    "SUBMIT": "Invia",
    "SYSTEM": "Sistema",
    "SYSTEM_HANDLED_BY_DI": "Accensione sistema gestito da periferica esterna (Input Digitale)",
    "SYSTEM_TEMPALTES_SCRIPT_GEN": "Generazione script default template",
    "MBOX": "mBox",
    "SYSTEM_DESC_1": "Non interrompe l\'alimentazione elettrica a nessuna delle apparecchiature controllate. È uno \"",
    "SYSTEM_DESC_2": "spegnimento del software",
    "SYSTEM_DESC_3": "\" che mette il sistema in standby e impedisce l\'attivazione di qualsiasi apparecchiatura. Il sistema rimane in standby fino a quando l\'utente manualmente reimpostarlo su on (simbolo ON). L\'interfaccia utente rimane attiva e legge tutti i sensori. L\'utente può apportare modifiche alle impostazioni.",
    "SYSTEM_INFO": "Informazioni del sistema",
    "SYSTEM_SETTINGS": "Impostazioni del sistema",
    "SYSTEM_NAME": "Nome del sistema",
    "SYSTEM_ON_OFF": "Sistema ON/OFF",
    "SYSTEMS": "Impianti",
    "TABLE": "Strutture dati",
    "TANK": "Serbatoio",
    "MAINTENANCE_TEMP": "Temp di mantenimento",
    "TECH_MODE": "Modalità tecnica",
    "TECHNICAL": "Tecnico",
    "TECHNICAL_PASSWORD": "Per favore inserisci la tua passowrd tecnica",
    "TEMP_UNITS": "Unitá Temperatura",
    "TEMPERATURE": "Temperatura",
    "TEMPERATURE_H": "Temperatura di riscaldamento",
    "TEMPERATURE_C": "Temperatura di raffrescamento",
    "TEMPERATURE_DIFFERENTIAL_FOR_ATTENUATION": "Differenziale di attenuazione",
    "TEMPLATE": "Template",
    "TEMPLATE_CREATED": "Template Creato",
    "TEMPLATE_SAVED": "Template Salvato",
    "TEMPLATES_PAGE": "Templates",
    "TERMS_OF_USE_TITLE": "Termini di Utilizzo e Informativa sulla Privacy",
    "TERMS_OF_USE_MESSAGE_START": "Per continuare, devi accettare i nostri ",
    "TERMS_OF_USE_MESSAGE_AND": " e l'",
    "TERMS_OF_USE": "Termini di Utilizzo",
    "TERMS_LABEL": "Ho letto e sono d'accordo con i Termini di Utilizzo e accetto l'Informativa sulla Privacy",
    "MUST_ACCEPT_USE_TERMS": "I nostri Termini di Utilizzo sono cambiati. Verrai disconnesso in modo da permetterti di leggere ed accettare i nostri nuovi Termini di Utilizzo.",
    "MUST_ACCEPT_USE_TERMS_ERROR": "Per continuare, devi accettare i nostri Termini di Utilizzo e l'Informativa sulla Privacy.",
    "AGREE_TERMS": "Accetto",
    "VIEW_TERMS": "Termini",
    "VIP_LABEL": "Utente vip",
    "TARGET_TEMP": "Temperatura target",
    "THE_SYSTEM_IS_OFF": "Sistema spento",
    "THE_UNITY": "L'unità",
    "TIMER": "Timer",
    "TIMER_STATUS": "Stato timer",
    "TIMERS": "Timer",
    "TMP": "Template",
    "TMP_DESCRIPTION": "Descrizione",
    "TMP_KEYS": "Chiavi template",
    "TMP_NAME": "Nome template",
    "TMR": "Timer",
    "TNK": "Accumulatore",
    "TO": "A",
    "TODAY": "Oggi",
    "TOMORROW": "Domani",
    "TRG": "Trigger",
    "TWO_DAYS": "2 Giorni",
    "TYPE_CONFIG_CODE": "inserisci codice di accesso",
    "TYPE_ARS": "Macchine aria",
    "TYPE_ATU": "Unità di trattamento dell'aria",
    "TYPE_DHW": "Impianti sanitari",
    "TYPE_ENR": "Risorse Raffrescamento/Riscaldamento",
    "TYPE_EXP": "Expansion Modules",
    "TYPE_FLS": "Flow sensors",
    "TYPE_FNC": "Fan coils",
    "TYPE_GRP": "Gruppi",
    "TYPE_HYS": "Impianti Idraulici",
    "TYPE_MCU": "Unitá di Controllo Messana",
    "TYPE_MCZ": "Macrozone",
    "TYPE_MXV": "Miscelatori",
    "TYPE_ODS": "Sensori Esterni",
    "TYPE_PMP": "Pompe",
    "TYPE_PRS": "Sensori di Pressione",
    "TYPE_RMS": "Stanze",
    "TYPE_SCH": "Schedulazioni",
    "TYPE_SHT": "Shutters",
    "TYPE_TMP": "Templates",
    "TYPE_TMR": "Timers",
    "TYPE_TNK": "Accumulatori",
    "TYPE_TRG": "Triggers",
    "TYPE_ZON": "Aree",
    "TYPE_GEN": "Generici",
    "TYPE_FWA": "Aree di Pavimento Riscaldato",
    "TYPE_MRM": "Centrali Termiche",
    "TYPE_MNF": "Collettori",
    "TYPE_DVV": "Valvole di Deviazione",
    "TYPE_CMN": "Periferiche Cool Automation",
    "UNABLE_TO_DELETE_SCHEDULATION": "Schedulazione in uso, impossibile cancellare",
    "UPDATE": "Aggiornamento",
    "UPDATE_NETWORK_CONFIGURATION": "Aggiorna",
    "RESTORE_NETWORK_CONFIGURATION": "Ripristina",
    "USED_NAME": "Nome non specificato o già utilizzato",
    "USED_ON_OTHER_SEASON": "Potrebbe essere usata in altra stagionalitá",
    "USING_DHCP": "Usa DHCP",
    "VENTILATION": "Ventilazione",
    "WARNINGS_HOME": "Avvisi",
    "WATER_SUPPLY_TEMP": "Temperatura acqua",
    "WATER_SUPPLY_TEMPERATURE": "Temperatura acqua",
    "WEEKLY_SCHEDULE": "Schedulazione settimanale",
    "NO_LOCAL_DEVICES_FOUND": "Nessun dispositivo locale rilevato",
    "NO_WIFI": "Nessuna connessione senza fili rilevata. Per favore, abilita la connessione WiFi per individuare i toui dispositivi nella rete locale.",
    "WARNING_OTHER_SERVED_ZONES": "serve anche altre zone. Sei sicuro di voler continuare?",
    "WORK": "Lavoro",
    "YES": "Sì",
    "YESTERDAY": "Ieri",
    "YOUR_ZONES": "Le mie zone",
    "GOING_TO": "Sto realizzando i tuoi desideri<br>attendi per favore",
    "ZIP_CODE": "Codice postale",
    "ZON": "Area",
    "ZONE_REQUEST": "Richiesta zone",
    "ZONE_TERMINALS": "Terminali di zona",
    "ZONE_WEIGHT": "Elenco delle zone e dei loro pesi",
    "ZONES": "Zone",
    "ACTIVATIONS": "Attivazioni",
    "HEATING_PLUS_COOLING": "Riscaldamento e Raffrescamento",
    "ONLY_HEATING": "Solo riscaldamento",
    "ONLY_COOLING": "Solo raffrescamento",
    "ZONE_SETPOINT": "setpoint di zona",
    "ON_SET_POINT_HUM": "Sul set point",
    "ON_SET_POINT_NTD": "Sul set point",
    "ON_SET_POINT_NTD_SUBTITLE": "Deumidificazione sempre attiva.",
    "ENERGY_SAVING_NTD": "Risparmio energetico",
    "EMAIL_UNDEF": 'E-mail non inviata, sembra che il tuo indirizzo e-mail sia indefinito, per favore, contattaci a info@radiantcooling.com',
    "EMAIL_NOT_SENT": 'A causa di un nostro problema l\'e-mail non è stata inviata',
    "ON_SET_POINT_CO2": "Setpoint",
    "SET_DEW_POINT_CO2": "Set punto di rugiada",
    "NTD_ES_SUBTITLE": "La richiesta di deumidificazione é ON solo quando la zona richiede il raffrescamento.",
    "NTD_ES_SUBTITLE_MOBILE": "La richiesta di deum. é ON solo con zona in raffrescamento.",
    "C02_SUBTITLE_ROW1_ZONE": "Per impostare i setpoint per ogni zona servita dall'unità",
    "C02_SUBTITLE_ROW1": "Applicato a tutte le zone servite con sensore IAQ",
    //"C02_SUBTITLE" : "<700 <i class=\"far fa-laugh-beam messana-green cleanIcon\"></i> Eccellente, <1000 <i class=\"far fa-smile messana-yellow cleanIcon\"></i> Buono, <1600 <i class=\"far fa-meh messana-orange cleanIcon\"></i> Discreto",
    "C02_SUBTITLE": "<700 <i class=\"far fa-laugh-beam messana-green cleanIcon pr-1\"></i> <1000 <i class=\"far fa-smile messana-yellow cleanIcon pr-1\"></i> <1600 <i class=\"far fa-meh messana-orange cleanIcon\"></i>",
    "SETPOINT_MODE_HUM": "Setpoint umidificazione",
    "SETPOINT_MODE_DEH": "Setpoint deumidificazione",
    "SETPOINT_MODE_IAQ": "Setpoint qualitá dell'aria",
    "SETPOINT_GLOBALLY": "Gestiti dalle macchine aria",
    "SETPOINT_ZONES": "Gestiti dalle zone",
    "AIR_QUALITY": "Qualitá aria",
    "DATE_FROM": "Data inizio",
    "DATE_TO": "Data fine",
    "CUSTOM_DAY": "Personalizzata",
    "CUSTOMIZE_YOUR_PARAMETERS": "Imposta se visualizzare o meno i punti sui grafici e specifica la funzione di interpolazione preferita.",
    "EXIT_GRAPH_TITLE": "Attenzione",
    "EXIT_GRAPH_MSG": "Sei sicuro di voler uscire?",
    "EXIT": "Esci",
    "DEHUM_INT_AIRFLOW_SETTINGS": "Dehum/Int settaggi flusso d'aria",
    "DEHUM_INT_AIRFLOW_TEXT1": "Le velocità sottostanti sono utilizzate per evitare il congelamento dell'evaporatore a causa di un flusso d'aria insufficiente durante le funzioni di deumidificazione e/o integrazione.",
    "DEHUM_INT_AIRFLOW_TEXT2": "L'unità funzionerà alle massime velocità tra le velocità impostate nella funzione HRV e le velocità seguenti.",
    "CHANGE_MACROZONES_SETPOINTS": "Edita limiti setpoint",
    "MACROZONES_SETPOINTS": "Limiti setpoint",
    "SETTEMP_MIN_COMMON": "Setpoint MIN",
    "SETTEMP_MAX_COMMON": "Setpoint MAX",
    "SETTEMP_MIN_H": "Setpoint MIN (H)",
    "SETTEMP_MIN_C": "Setpoint MIN (C)",
    "SETTEMP_MAX_H": "Setpoint MAX (H)",
    "SETTEMP_MAX_C": "Setpoint MAX (C)",
    "SET_POINT": "Setpoint",
    "HEATING_SET_POINT": "Setpoint riscaldamento",
    "COOLING_SET_POINT": "Setpoint raffrescamento",
    "SETPOINT_SEASONALLY_LIMIT": "Setpoints duale (H verso C)",
    "SETPOINT_UNIQUE_LIMIT": "Setpoint Unico",
    "VIA_TIMER": "Via Timer",

    "CONFIRM_UPDATE_NETWORK": "Sei sicuro?",
    "CONTINUE": "Continue",
    "CHANGE": "Change",
    "REMOVE_FROM_MACROZONE": "Rimuovi",
    "REMOVAL": "Rimozione",
    "REMOVE": "Rimuovi",
    "REMOVE_FROM_MACROZONE_TITLE": "Rimuovi da macrozona",
    "REMOVE_FROM_MACROZONE_TEXT": "Sei sicuro di voler rimuovere questa zona dalla macrozona?",
    "FANCOIL_DIFFERENTIAL": "Differenziale Max Velocitá",
    "FANCOIL_SETTINGS": "Impostazioni fancoil",


    "FAD": "Ritardo attivazione fancoil",
    "FAD_DESC": "Ritarda l'attivazione della ventola finché la temperatura di mandata non raggiunge una temperatura minima, in riscaldamento (H), o una temperatura massima in raffreddamento (C), per evitare l'avvio di aria fredda o calda.",
    "FAD_MIN_SUPPLY_TEMP_H": "Minima temperatura di mandata (Risc)",
    "FAD_MIN_SUPPLY_TEMP_H_DESC": "In riscaldamento, la ventola si avvia dopo che la temperatura di mandata è salita al di sopra di",
    "FAD_MAX_SUPPLY_TEMP_C": "Massima temperatura di mandata (Raff)",
    "FAD_MAX_SUPPLY_TEMP_C_DESC": "In raffrescamento, la ventola si avvia dopo che la temperatura di mandata è scesa al di sotto di",
    "FAD_DIFF": "Differenziale FAD",
    "FAD_DIFF_DESC_1": "La ventola si arresta se la temperatura di mandata scende al di sotto di",
    "FAD_DIFF_DESC_2": ", in riscaldamento, o al di sopra di",
    "FAD_DIFF_DESC_3": "in raffrescamento.",
    "FAD_DELAY": "Ritardo",
    "FAD_DELAY_ON_DESC_1": "Aspetta fino a",
    "FAD_DELAY_ON_DESC_2": "affinché la temperatura di mandata raggiunga le condizioni di cui sopra. Dopo",
    "FAD_DELAY_ON_DESC_3": "la ventola parte comunque.",
    "FAD_DELAY_OFF_DESC": "La ventola non si avvierà se le condizioni di cui sopra non sono soddisfatte.",
    "FAD_WARNING_DELAY": "Ritardo avvertenza",
    "FHS":"Impostazioni impianto idronico fancoil",
    "FHS_SUPPLY_TEMP_H":"Temperatura obiettivo mandata acqua (Risc)",
    "FHS_SUPPLY_TEMP_C":"Temperatura obiettivo mandata acqua (Raff)",
    "FHS_SUPPLY_TEMP_DEHUM":"Temp mandata (Deum)",
  "FHS_SUPPLY_TEMP_H_MOBILE":"Temp mandata (Risc)",
  "FHS_SUPPLY_TEMP_C_MOBILE":"Temp mandata (Raff)",
  "FHS_SUPPLY_TEMP_DEHUM_MOBILE":"Temp mandata (Deum)",
    "FHS_SUPPLY_TEMP_H_DESC":"Temperatura obiettivo del sistema idronico quando un ventilconvettore è attivo in riscaldamento.",
    "FHS_SUPPLY_TEMP_C_DESC":"Temperatura obiettivo del sistema idronico quando un ventilconvettore è attivo in raffrescamento.",
    "FHS_SUPPLY_TEMP_DEHUM_DESC":"Temperatura obiettivo del sistema idronico quando un ventilconvettore è in deumidificazione.",
    "FHS_DESC":"Imposta le temperature di mandata per i ventilconvettori associati a questo sistema idronico.",
    "ACCUWEATHER_LOCATION_KEY": "Chiave AccuWeather di località",
    "ACCUWEATHER_NOT_FOUND": "AccuWeather location key non trovata",
    "ACCUWEATHER_CHECK_SETTINGS": "Controlla Impostazioni/Generale/AccuWeather Location Key",
    "ACCUWEATHER_INVALID": "AccuWeather location key non é valida",
    "ACCUWEATHER_UNAVAILABLE": "AccuWeather non é al momento raggiugibile",
    "RETURN_TO_DASHBOARD_TIMEOUT": "Dopo",
    "RETURN_TO_DASHBOARD": "Ritorna alla dashboard dopo inattivitá",
    "UNATHORIZED_USER": "Utente non autorizzato",
    "DIFF": "Diff",
    "AVG_AIR_TMP": "Setpoint dell'aria di mandata",
    "AVG_AIR_TMP_MOBILE": "Setpoint dell'aria di mandata",
    "AVG_AIR_TMP_INFO": "L'aria più calda trattiene l'umidità in modo più efficace e la mantiene confortevole.",
    "DP_MAX": "Massimo punto di rugida della stanza",
    "RH_MAX": "Massimo umiditá relativa della stanza",
    "AVG_DIFF_AIR_H": "Media dei setpoint in riscaldamento delle stanze + diff",
    "AVG_DIFF_AIR_C": "Media dei setpoint in raffrescamento delle stanze + diff ",
    "ROOMS_RH_MAX": "Blocca umidificazione se l'umiditá eccede nelle stanze",
    "SELECTED_ROOMS": "Stanze selezionate",
    "SELECT_ALL": "--- Seleziona tutto ---",
    "MAX_ROOM_HUM": "Massima umiditá della stanza",
    "USER_PREFERENCES": "Preferenze utente",
    "USER_PREF_TITLE": "Queste preferenze sono memorizzate localmente all'interno del browser dell'utente in ciascun dispositivo.",
    "GENERAL_TITLE": "Queste impostazioni sono memorizzate localmente nell'unità di controllo.",
    "WRONG_SELECTION": "Selezione errata",
    "WRONG_CODE": "Codice errato",
    "WRONG_SELECTION_MSG": "I valori verranno ripristinati",
    "ZONES_MACROZONES": "Zone/Macrozone",
    "SETPOINT_SEASONALLY_TITLE": "I setpoint della temperatura nelle stanze possono essere mantenuti separati tra le modalità H e C (duali) o unificati (comuni). ",
    "SETPOINT_SEASONALLY_EXPL": "Con setpoint comuni e terminali di riscaldamento e raffrescamento indipendenti che possono essere attivati contemporaneamente, è necessario mantenere un differenziale tra i setpoint di riscaldamento e raffrescamento per evitare attivazioni simultanee.",
    "CONFIRM_SP_MODE_OFF": "I setpoint comuni verranno applicati a due distinti insiemi di setpoint per il riscaldamento e per il raffrescamento e verranno tenuti separati. Vuoi continuare?",
    "CONFIRM_SP_MODE_ON": "I setpoint di riscaldamento verranno applicati ai setpoint comuni. Le schedulazioni usate nelle zone per il riscaldamento verranno applicate alla modalità raffrescamento. Tutti i setpoint e le impostazioni di schedulazione in raffrescamento verranno perse. Questa operazione non è reversibile. Vuoi continuare?",
    "GRAPHS": "Storici",
    "HUM_SETPOINT_EXPL": "I setpoint dell'umidità possono essere impostati a livello di macchina aria, e vengono applicati a ciascuna zona servita dalla macchina aria (Gestiti dalle macchine aria), oppure possono essere impostati individualmente per ciascuna zona (Gestiti dalle zone).",
    "NTD_SETPOINT_EXPL": "I setpoint di umidità possono essere impostati a livello di macchina aria, e vengono applicati a ciascuna zona servita dalla macchina aria (Gestiti dalle macchine aria), oppure possono essere impostati individualmente per ciascuna zona (Gestiti dalle zone).",
    "IAQ_SETPOINT_EXPL": "I setpoint della qualità dell'aria possono essere impostati a livello di macchina aria, e vengono applicati a ciascuna zona servita dalla macchina aria (Gestiti dalle macchine aeree), oppure possono essere impostati individualmente per ciascuna zona (Gestiti dalle zone).",
    "CMN": "Servizio Com CoolMasterNet",
    "STOP_CMN": "Stop",
    "START_CMN": "Avvia",
    "RESTART_CMN": "Riavvia",
    "STOP_CMN_CONFIRM": "Sei sicuro di voler fermare il servizio CoolMasterNet?",
    "START_CMN_CONFIRM": "Sei sicuro di voler avviare il servizio CoolMasterNet?",
    "RESTART_CMN_CONFIRM": "Sei sicuro di voler riavviare il servizio CoolMasterNet?",
    "STOP_CMN_ONGOING": "Sto fermando CoolMasterNet...",
    "START_CMN_ONGOING": "Sto avviando CoolMasterNet...",
    "RESTART_CMN_ONGOING": "Sto riavviando CoolMasterNet...",
    "UNIT_PROBES": 'Sonde unità',
    "PROBES_ON_BOARD": "a bordo",
    "PROBES_ON_DISPLAY": "su display",
    // Analogic ATU
    "CURRENT_FAN_SPEED": "Velocità manuale della ventola",
    "LOW_FAN_SPEED": "Velocità ventola bassa",
    "HIGH_FAN_SPEED": "Velocità ventola alta",
    "LOW_ECO_2_SETPOINT": "eCO<sub>2</sub> setpoint basso",
    "HIGH_ECO_2_SETPOINT": "eCO<sub>2</sub> setpoint alto",
    "SLEEP_MODE_DESC": "Durante le ore della modalità di sonno, la velocità della ventola sarà automaticamente ridotta alla velocità impostata di seguito.",
    "SLEEP_MODE_FAN_SPEED": "Velocità della ventola in modalità di sonno",
    "BATHROOM_EXHAUST": "Estrattore bagno",
    "BATHROOM_EXHAUST_DESC": "La funzione di estrazione del bagno viene utilizzata quando un ERV o un HRV ha i suoi tubi di scarico collegati ai bagni. Quando l'umidità in un bagno associato a un ERV/HRV supera il punto di umidità impostato, l'ERV/HRV aumenterà la velocità della ventola al massimo fino a quando l'umidità scende al di sotto del punto di impostazione o viene raggiunto il tempo di funzionamento massimo. Se è installato un sensore di presenza o occupazione, può anche essere utilizzato per attivare l'ERV/HRV affinché funzioni a una determinata velocità e per un periodo massimo di funzionamento.",
    "BATHROOM_MODE_FAN_SPEED": "Velocità della ventola modalità bagno",
    "HUMIDITY_SETPOINT": "Setpoint umidità",
    "MAXIMUM_RUN_TIME": "Durata massima",
    "PRESENCE": "Presenza",
    "PRESENCE_FAN_SPEED": "Velocità ventola",
    "ANALOGIC_ERV_IS_IAQ_WARNING": "IAQ setpoint per zone non supportato con ATU analogico.",
    "HRV_LOW_SPEED_DESC_1": "Bassa velocità: Velocità della ventola dell'unità quando i livelli rilevati di eCO2 scendono al di sotto del setpoint di eCO2 basso. Si trova tra",
    "HRV_LOW_SPEED_DESC_2": "(o inferiore del 20% rispetto alla Velocità Ventola Alta) della capacità massima della ventola",
    "HRV_HIGH_SPEED_DESC_1": "Velocità della ventola dell'unità quando i livelli rilevati di eCO2 superano il setpoint di eCO2 alto. Si trova tra",
    "HRV_HIGH_SPEED_DESC_2": "(o superiore del 20% rispetto alla Velocità Ventola Bassa) e",
    "HRV_HIGH_SPEED_DESC_3": "della capacità massima della ventola",
    "HRV_LOW_ECO2_DESC_1": "Questo è la soglia al di sotto della quale l'unità funziona a Velocità Ventola Bassa. Si trova tra",
    "HRV_LOW_ECO2_DESC_2": "(o inferiore di 200 rispetto al setpoint di eCO2 alto)",
    "HRV_HIGH_ECO2_DESC_1": "Questo è il limite superiore al di sopra del quale l'unità funziona a Velocità Ventola Alta. Si trova tra",
    "HRV_HIGH_ECO2_DESC_2": "(o superiore di 200 rispetto al setpoint di eCO2 basso) fino a",
    "DEFAULT_VALUE": "valore predefinito",
    "ERV_HRV_TITLE": "Controllo della velocità della ventola adattivo alla qualità dell'aria",
    "ERV_HRV_DESCRIPTION": "La velocità dell'unità di ERV/HRV si regola in base ai livelli rilevati di eCO2, che è una misura della qualità dell'aria interna. Quando i livelli di eCO2 scendono al di sotto del punto di settaggio di eCO2 basso, l'unità funziona a bassa velocità. Al contrario, quando i livelli di eCO2 superano il punto di settaggio di eCO2 alto, l'unità funziona ad alta velocità. Per i livelli intermedi di eCO2, l'unità modula linearmente la velocità tra le velocità bassa e alta. NOTA: il livello considerato è sempre la lettura di eCO2 più alta tra tutte le zone servite dall'unità, garantendo così una ventilazione adeguata anche nelle zone con la peggiore ventilazione.",
    "UNIT_SIZE": "Dimensione unità",
    "AIRFLOW": "Flusso d'aria",
    "UNIT_SIZE_DESC": "Imposta la dimensione dell'unità ERV / HRV in cfm",
    "AIRFLOW_DESC": "Valutato in base alla dimensione dell'unità e alla percentuale di velocità attuale della ventola",
    "AND": "e",
    "to": "a",
    /* Floor warmer */
    "FLOOR_TEMPERATURE": "Temperatura del pavimento",
    "SETPOINT": "Setpoint",
    "FLOOR_WARMER_MAX_SUPPLY_TEMP": "Temp. max mandata scaldapavimenti",
    "FLOOR_SENSOR_TEMP": "Temp sensore del pavimento",
    "CALIBRATION_DIFFERENTIAL": "Differenziale di calibrazione",
    "CALIBRATION_DIFFERENTIAL_DESC": "Misura la temperatura della superficie del pavimento con un termometro ad infrarossi e applica la differenza alla lettura del sensore.",
    "LOGICS": "Logiche",
    "PROJECT_NUMBER": "Numero Progetto",
    "ENABLE_WHEN_COOLING": "Abilita in raffrescamento",
    "ENABLE_WHEN_COOLING_ES": "Abilita con Risparmio Energetico",
    "ENABLE_WHEN_COOLING_ES_DESC": "Quando un sensore di stanza correlato soddisfa una richiesta di raffreddamento.",
    "OVERHEATING_DIFFERENTIAL": "Delta di surriscaldamento",
    "OVERHEATING_DIFFERENTIAL_DESC": "Quando un sensore di stanza è in richiesta di riscaldamento, il riscaldamento del pavimento si interrompe se la temperatura misurata dal sensore di stanza supera il setpoint del sensore di stanza di",
    "MAX_OPERATIVE_TIME_COOLING": "Tempo massimo durante il raffreddamento",
    "DUAL_SETPOINT_MODE": "Modalità del doppio setpoint",
    "CHANGEOVER_SCHEDULES_WARNING_1": "<br><br><b>Avviso:</b> Hai schedulazioni",
    "CHANGEOVER_SCHEDULES_WARNING_1_ALT": "Hai schedulazioni",
    "CHANGEOVER_WARNING_VARIABLE": "Variabili",
    "CHANGEOVER_WARNING_DUAL_VARIABLE": "Variabili Duali",
    "CHANGEOVER_SCHEDULES_WARNING_2": "applicate alle zone o macrozone. Tutte le schedulazioni",
    "CHANGEOVER_SCHEDULES_WARNING_3": "attualmente assegnate saranno disassociate.",
    "DUAL_SETPOINT_HEATING_OVERRIDE_HIGH_TEMP_WARNING": "Attenzione: questo override del setpoint del riscaldamento è superiore alla differenza minima prevista con il setpoint del raffrescamento. Se confermi l'override, anche il setpoint del raffrescamento verrà messo in override, ed entrambi gli overrides potrebbero essere collegati.",
    "DUAL_SETPOINT_COOLING_COOLING_LOW_TEMP_WARNING": "Attenzione: questo override del setpoint di raffrescamento è inferiore alla differenza minima prevista con il setpoint del riscaldamento. Se confermi l'override, anche il setpoint del riscaldamento verrà messo in override, ed entrambi gli overrides potrebbero essere collegati.",
    "DUAL_SETPOINT_CANCEL_HEATING_OVERRIDE_WARNING": "Attenzione: se cancelli questo override del setpoint di riscaldamento, cancellerai anche automaticamente l'override del setpoint di raffrescamento.",
    "DUAL_SETPOINT_CANCEL_COOLING_OVERRIDE_WARNING": "Attenzione: se cancelli questo override del setpoint di raffrescamento, cancellerai anche automaticamente l'override del setpoint del riscaldamento.",
    "DUAL_SETPOINT_UPDATE_TIME_HEATING_INCREASE_OVERRIDE_WARNING": "Attenzione: se aumenti la durata di questo override del setpoint del riscaldamento, aumenterai anche la durata dell'override del setpoint di raffrescamento",
    "DUAL_SETPOINT_UPDATE_TIME_COOLING_INCREASE_OVERRIDE_WARNING": "Attenzione: se aumenti la durata di questo override del setpoint di raffrescamento, aumenterai anche la durata dell'override del setpoint del riscaldamento",
    "DUAL_SETPOINT_UPDATE_TIME_HEATING_DECREASE_OVERRIDE_WARNING": "Attenzione: se diminuisci la durata di questo override del setpoint del riscaldamento, diminuirai anche la durata dell'override del setpoint di raffrescamento",
    "DUAL_SETPOINT_UPDATE_TIME_COOLING_DECREASE_OVERRIDE_WARNING": "Attenzione: se diminuisci la durata di questo override del setpoint di raffrescamento, diminuirai anche la durata dell'override del setpoint del riscaldamento",
    "WHEN_ENABLED": "Se abilitato",
    "ENERGY_SAVING_DESCRIPTION_GENERAL": "tutte le temperature obiettivo dei sensori delle stanze verranno diminuite (per il riscaldamento) o aumentate (per il raffrescamento) di",
    "ENERGY_SAVING_DESCRIPTION_DEHUMIDIFICATION_DEW_POINTS": "L'obiettivo del punto di rugiada di deumidificazione di tutti i sensori delle stanze è aumentato di",
    "ENERGY_SAVING_DESCRIPTION_DEHUMIDIFICATION_RELATIVE": "l'obiettivo dell'umidità relativa di deumidificazione di tutti i sensori delle stanze è aumentata del",
    "ENERGY_SAVING_DESCRIPTION_HUMIDIFICATION_DEW_POINTS": "L'obiettivo del punto di rugiada di umidificazione di tutti i sensori delle stanze è diminuito di",
    "ENERGY_SAVING_DESCRIPTION_HUMIDIFICATION_RELATIVE": "l'obiettivo dell'umidità relativa di umidificazione di tutti i sensori delle stanze è diminuita del",
    "ENERGY_SAVING_DESCRIPTION_FWA_GENERAL_1": "Tutte le temperature obiettivo degli scaldapavimenti sono diminuite di",
    "ENERGY_SAVING_DESCRIPTION_FWA_GENERAL_2": "Una richiesta di riscaldamento a pavimento viene soppressa se il sensore di una stanza correlata è spento, ed il risparmio energetico è abilitato",
    "ENERGY_SAVING_DESCRIPTION_FWA_IN_COOLING": "Una richiesta di riscaldamento a pavimento viene soppressa se il sistema idronico di un sensore di una stanza correlata è in modalità raffrescamento, ed il risparmio energetico è abilitato",
    "THIS_SYSTEM": "Questo sistema",
    "CHANGE_AAC_THE_GROUP_1": "Il gruppo",
    "CHANGE_AAC_THE_GROUP_2": "",
    "AacMinTimeTS": "Tempo minimo stagione temp.",
    "AacMinTimeRS": "Tempo minimo stagione di rif.",
    "CHANGE_AAC_MIN_TIME_TS_DESCRIPTION": "rimarrà nella stagione temporanea per un periodo minimo prima di tornare alla stagione di riferimento.",
    "CHANGE_AAC_MIN_TIME_RS_DESCRIPTION": "rimarrà nella stagione di riferimento per un periodo minimo prima di tornare alla stagione temporanea.",
    "CURRENT_REMAINING_TIME_TS_RS": "Il tempo rimanente fino al cambio è:",
    "ONCE_ADAPTIVE_COMFORT_ENGAGES": "Quando il comfort auto-adattivo si attiva",
    "ONCE_ADAPTIVE_COMFORT_DISENGAGES": "Quando il comfort auto-adattivo si disattiva",
    // "AAC_CURRENT_REQUESTS": "Richieste attuali di CAA",
    "AAC_CURRENT_REQUESTS_COOLING": "Richieste raffrescamento",
    "AAC_CURRENT_REQUESTS_HEATING": "Richieste riscaldamento",
    "AAC_CURRENT_REQUESTS_DESCRIPTION_1": "Questa barra mostra lo stato di voto delle zone correnti:",
    "AAC_CURRENT_REQUESTS_DESCRIPTION_ORANGE": "l'<b>arancione</b>",
    "AAC_CURRENT_REQUESTS_DESCRIPTION_BLUE": "il <b>blu</b>",
    "AAC_CURRENT_REQUESTS_DESCRIPTION_2": "rappresenta la percentuale di zone che richiedono",
    "AAC_CURRENT_REQUESTS_DESCRIPTION_HEATING": "il riscaldamento",
    "AAC_CURRENT_REQUESTS_DESCRIPTION_COOLING": "il raffrescamento",
    "AAC_CURRENT_REQUESTS_DESCRIPTION_3": "mentre la linea verticale indica la soglia che deve essere superata per innescare un cambio di stagione.",
    "ZONE_VOTING_STATUS": "Stato di voto delle zone",
    "CURRENT_ZONE_VOTING_STATUS": "Stato di voto corrente delle zone",
    "LAST_ZONE_VOTING_STATUS": "Ultimo stato di voto delle zone",
    "ZONES_VOTING_STATUS_DESCRIPTION_1": "Questa barra mostra",
    "ZONES_VOTING_STATUS_DESCRIPTION_2_CURRENT": "l'attuale stato",
    "ZONES_VOTING_STATUS_DESCRIPTION_2_LAST": "l'ultimo stato",
    "ZONES_VOTING_STATUS_DESCRIPTION_3": "di votazione delle zone: l'<b>arancione</b> rappresenta la percentuale di zone che richiedono il riscaldamento, il <b>blu</b> rappresenta le zone che richiedono raffreddamento, e il <b>grigio</b> indica le zone che sono neutrali.",
    "ZONE_LIST_WEIGHTS_DESCRIPTION": `Se una zona è disabilitata (modalità zona = OFF) o fuori schedulazione (modalità zona = Schedulazione), non partecipa alla condizione di attivazione.<br><br>Ogni zona che partecipa alla condizione di attivazione può avere un peso diverso per partecipare alla condizione di attivazione (sia il 20% che l'80% sono validi).<br><br>Per impostazione predefinita, ogni zona ha lo stesso peso pari a 1, tuttavia il peso può essere impostato da 0 a 32767 (questa impostazione non è disponibile per l'utente, solo un installatore addestrato dalla Messana può cambiarla).<br><br>Il peso della zona può essere relativo all'area della zona (una zona più grande può avere maggiore influenza) o al tipo di zona (ad esempio, una camera matrimoniale può avere maggiore influenza, mentre i corridoi, i soggiorni e gli armadi hanno meno influenza).<br><br>Se una zona deve essere esclusa dall'evento di attivazione, può essere impostata con peso = 0.`,
    "ADAPTIVE_COMFORT_SETTINGS": "Impostazioni comfort auto-adattivo",
    "ADAPTIVE_COMFORT_SETTINGS_DESCRIPTION": "Il comfort adattivo è una funzione particolare che consente un passaggio temporaneo dal riscaldamento al raffrescamento o viceversa. In particolare, in inverno, in condizioni calde (ad esempio, assorbendo calore solare dalle finestre), la funzione di comfort adattivo consente di passare dalla modalità riscaldamento alla modalità \"raffrescamento" +
        " temporaneo\" o in estate, in condizioni particolarmente fredde, dalla modalità raffrescamento alla modalità \"riscaldamento temporaneo\".<br>Il passaggio avviene solo quando viene raggiunta una \"condizione di attivazione\".",
    "ORIGINATING_MODE_TEMPORARY_MODE_TITLE": "Modalità originaria e modalità temporanea",
    "ORIGINATING_MODE_TEMPORARY_MODE_DESCRIPTION": "Con il comfort adattivo attivato, quando viene raggiunta una certa condizione (condizione di attivazione), il sistema passa temporaneamente dalla modalità attuale impostata nel sistema (modalità originaria, riscaldamento o raffrescamento) alla modalità opposta, dal riscaldamento al raffrescamento temporaneo o dal raffrescamento al riscaldamento temporaneo (modalità temporanea).",
    "SETPOINTS_TEMP_MODE_TITLE": "Setpoint nella modalità temporanea (riscaldamento/raffrescamento temporaneo)",
    "SETPOINTS_TEMP_MODE_DESCRIPTION": "È importante notare che quando il sistema passa alla modalità temporanea, i setpoint di tutte le zone si riferiscono ancora alla modalità originaria. Il \"setpoint di zona\" si riferisce sempre al setpoint nella modalità originaria.",
    "Adaptive comfort &#37; Min": "Comfort adattivo &#37; Min",
    "Adaptive comfort &#37; Max": "Comfort adattivo &#37; Max",
    "Adaptive comfort return &Delta;T": "Ritorno comfort adattivo &Delta;T",
    "Adaptive comfort activation &Delta;T/C": "Attivazione comfort adattivo &Delta;T/R",
    "Adaptive comfort activation &Delta;T/H": "Attivazione comfort adattivo &Delta;T/C",
    "TRIGGER_CONDITIONS": "Condizioni di attivazione",
    "TRIGGER_CONDITIONS_SUBTITLE": "Condizioni per passare dal riscaldamento al raffrescamento temporaneo:",
    "TRIGGER_CONDITIONS_SUBTITLE_A_1": "Se almeno una zona è attiva in modalità riscaldamento e il",
    "TRIGGER_CONDITIONS_SUBTITLE_A_2": "delle zone ha una temperatura operativa superiore al setpoint della zona +",
    "TRIGGER_CONDITIONS_SUBTITLE_A_3": "Esempio: setpoint di zona 72°F, temperature operative > 78°F in oltre l'80% delle zone.",
    "TRIGGER_CONDITIONS_SUBTITLE_B_1": "Se nessuna zona è attiva in modalità riscaldamento e il",
    "TRIGGER_CONDITIONS_SUBTITLE_B_3": "Esempio: setpoint di zona 72°F, temperature operative > 78°F in oltre il 20% delle zone.",
    "CONDITION_SWITCH_TEMP_HEATING_SUBTITLE": "Condizioni per passare dal raffrescamento al riscaldamento temporaneo:",
    "CONDITION_SWITCH_TEMP_HEATING_A_1": "Se almeno una zona è attiva in modalità raffrescamento e il",
    "CONDITION_SWITCH_TEMP_HEATING_A_2": "delle zone ha una temperatura operativa inferiore al setpoint della zona -",
    "CONDITION_SWITCH_TEMP_HEATING_A_3": "Esempio: setpoint di zona 74°F, temperature operative < 68°F in oltre l'80% delle zone.",
    "CONDITION_SWITCH_TEMP_HEATING_B_2": "delle zone ha una temperatura operativa inferiore al setpoint della zona -",
    "CONDITION_SWITCH_TEMP_HEATING_B_3": "Esempio: setpoint di zona 74°F, temperature operative < 68°F in oltre il 20% delle zone.",
    "TRIGGER_CONDITIONS_FINAL_SUBTITLE": "Il ΔT può essere impostato diversamente per il passaggio dal riscaldamento al raffrescamento e dal raffrescamento al riscaldamento (nota: nella vecchia interfaccia utente è lo stesso parametro, ma può essere modificato nel file XML se necessario).",
    "TEMP_MODE_SWITCH_TITLE": "Passaggio alla modalità temporanea (Attivazione)",
    "TEMP_MODE_SWITCH_DESCRIPTION": "Quando viene raggiunta la condizione di attivazione, il sistema passa dalla modalità originaria alla modalità temporanea. Durante il passaggio, l'interfaccia mostra sotto la modalità attiva “Heat->Cool” o “Cool->Heat” (questo dura tipicamente circa 10 minuti, il tempo necessario per chiudere tutti gli attuatori termici delle zone e attivare le valvole stagionali automatizzate). Quando la transizione è completata, verrà visualizzato “Temp.Cool” o “Temp.Heat” come nuova modalità temporanea attiva.",
    "CYCLING_PREVENTION_TITLE": "Prevenzione del ciclo",
    "CYCLING_PREVENTION_SUBTITLE_1": "Per prevenire i cicli, è previsto un tempo minimo per cui il sistema deve rimanere in modalità temporanea prima di tornare alla modalità originaria",
    "CYCLING_PREVENTION_SUBTITLE_2": "Una volta che il sistema torna alla modalità originaria, c'è un tempo minimo da rispettare prima di iniziare un nuovo ciclo in modalità temporanea",
    "CYCLING_PREVENTION_SUBTITLE_3": "Nella vecchia interfaccia utente questa impostazione può essere modificata solo nei file XML.",
    "SWITCH_BACK_ORIGINATING_MODE_TITLE": "Ritorno alla modalità originaria (Disattivazione)",
    "SWITCH_BACK_ORIGINATING_MODE_SUBTITLE": "Il ritorno alla modalità originaria può avvenire per due motivi:",
    "SWITCH_BACK_ORIGINATING_MODE_A_1": "Timeout: se il sistema rimane in modalità temporanea per più di",
    "SWITCH_BACK_ORIGINATING_MODE_A_2": "Questa condizione di ritorno può essere disabilitata o impostata su un tempo diverso.",
    "SWITCH_BACK_ORIGINATING_MODE_A_3": "Esempio: setpoint di zona 74°F, temperature operative < 68°F in oltre l'80% delle zone.",
    "SWITCH_BACK_ORIGINATING_MODE_B_1": "Condizione di comfort adattivo raggiunta, in particolare:",
    "SWITCH_BACK_ORIGINATING_MODE_B_2": "(estate) in riscaldamento temporaneo:",
    "SWITCH_BACK_ORIGINATING_MODE_B_LESS": "quando meno del",
    "SWITCH_BACK_ORIGINATING_MODE_B_4": "delle zone ha temperature operative superiori al setpoint della zona -",
    "SWITCH_BACK_ORIGINATING_MODE_B_5": "Esempio: setpoint di zona 76°F, temperature operative > 74°F in meno del 20% delle zone.",
    "SWITCH_BACK_ORIGINATING_MODE_B_6": "(inverno) in raffrescamento temporaneo:",
    "SWITCH_BACK_ORIGINATING_MODE_B_7": "delle zone ha temperature operative inferiori al setpoint della zona +",
    "SWITCH_BACK_ORIGINATING_MODE_B_8": "Esempio: setpoint di zona 72°F, temperature operative < 70°F in meno del 20% delle zone.",
    "SWITCH_BACK_ORIGINATING_MODE_NOTE_1": "Nota: quanto più alto è il set di",
    "SWITCH_BACK_ORIGINATING_MODE_NOTE_2": "meno il sistema rimane in modalità Temporanea.",
    "SWITCH_BACK_ORIGINATING_MODE_NOTE_3": "può essere impostato da",
    "AERAULIC_FUNCTIONS": "Funzioni aerauliche",
    "AERAULIC_FUNCTIONS_DESCRIPTION_1": "La funzioni aerauliche permettono di abilitare o disabilitare la ventilazione del fan coil e dell'HRV dell'ATU per la zona",
    "AERAULIC_FUNCTIONS_DESCRIPTION_2": ".",
    "COMPRESSOR": "Compressore",
    "COMPRESSOR_RUNNING_FREQUENCY": "Frequenza di funzionamento del compressore",
    "BLE_CONNECTION_FAILED_TITLE": "Connessione Bluetooth non riuscita",
    "BLE_CONNECTION_FAILED_TEXT": "La connessione tramite Bluetooth non è andata a buon fine. Vuoi riprovare a connetterti?",
    "BLE_CONNECTION_FAILED_TRY_CONNECT": "Riprova a connetterti",
    "BLE_CONNECTION_FAILED_LEAVE": "Esci",
};
