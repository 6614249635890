import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

  @Input() content: string = '';

}
