<ion-list>
  <ion-item class="no-lines item-for-input">
    <div class="w-100 d-flex flex-row ion-justify-content-center">

      <div class="d-flex flex-row ion-justify-content-between ion-align-items-center sixDigitRow">

        <div>
          <ion-input  (paste)="handlePaste($event)" #validationCodeBox  class="validationCodeBox"  [ngModelOptions]="{standalone: true}" [ngModel]="sixDigit[0]"
                     type="text"  (ionInput)="moveFocus(1, $event)"  (keydown)="handleBackspace(1, $event)" ></ion-input>
        </div>
        <div>
          <ion-input  (paste)="handlePaste($event)" #validationCodeBox  class="validationCodeBox"  [ngModelOptions]="{standalone: true}" [ngModel]="sixDigit[1]"
                     type="text"  (ionInput)="moveFocus(2, $event)"  (keydown)="handleBackspace(2, $event)" ></ion-input>
        </div>
        <div>
          <ion-input  (paste)="handlePaste($event)" #validationCodeBox class="validationCodeBox"  [ngModelOptions]="{standalone: true}" [ngModel]="sixDigit[2]"
                     type="text"  (ionInput)="moveFocus(3, $event)"  (keydown)="handleBackspace(3, $event)" ></ion-input>
        </div>
        <div>
          <ion-input  (paste)="handlePaste($event)" #validationCodeBox  class="validationCodeBox"  [ngModelOptions]="{standalone: true}" [ngModel]="sixDigit[3]"
                     type="text"  (ionInput)="moveFocus(4, $event)"  (keydown)="handleBackspace(4, $event)" ></ion-input>
        </div>
        <div>
          <ion-input  (paste)="handlePaste($event)" #validationCodeBox  class="validationCodeBox"  [ngModelOptions]="{standalone: true}" [ngModel]="sixDigit[4]"
                     type="text"  (ionInput)="moveFocus(5, $event)"  (keydown)="handleBackspace(5, $event)" ></ion-input>
        </div>
        <div>
          <ion-input  (paste)="handlePaste($event)" #validationCodeBox  class="validationCodeBox"  [ngModelOptions]="{standalone: true}" [ngModel]="sixDigit[5]"
                     type="text" (ionInput)="moveFocus(6, $event)"  (keydown)="handleBackspace(6, $event)" ></ion-input>
        </div>


      </div>
    </div>
  </ion-item>
</ion-list>
<div #outOfFocus></div>
