
export class User {

    constructor(
        public username: string = null,
        public email: string = null,
   //     public password: string = null,
        public id: string = null,
        public userOf: any[] = [],
        public guestOf: any[] = [],
        public masterOf: any[] = [],
        public residenceOf: any[] = [],
        public serviceOf: any[] = [],
        public favourite: any[] = [],
        public token: string = null,
        public admin: boolean = false,
        public mht_admin: boolean = false,
        public vip: boolean = false,
        public use_terms: boolean = false,
        public local: boolean = false,

    ) {}

}


export class UserCensus extends User {
  constructor(
    public password: string = null, // Campo aggiunto nella classe estesa
    username: string = null,
    email: string = null,
    id: string = null,
    userOf: any[] = [],
    guestOf: any[] = [],
    masterOf: any[] = [],
    residenceOf: any[] = [],
    serviceOf: any[] = [],
    favourite: any[] = [],
    token: string = null,
    admin: boolean = false,
    mht_admin: boolean = false,
    vip: boolean = false,
    use_terms: boolean = false,
    local: boolean = false
  ) {
    super(
      username,
      email,
      id,
      userOf,
      guestOf,
      masterOf,
      residenceOf,
      serviceOf,
      favourite,
      token,
      admin,
      mht_admin,
      vip,
      use_terms,
      local
    );
  }
}
